<template>
  <div class="d-flex flex-column">
    <ul class="main-nav">
      <li v-bind:class="{'active': currentPath == 'index'}">
        <router-link to="/">Accueil</router-link>
      </li>
      <!--    <li class="has-submenu" :class=" expertiseMenu ? 'active' : 'notactive'">
            <a href="javascript:void()">Expertise <i class="fas fa-chevron-down"></i></a>
            <ul class="submenu">
              <li :class="currentPath == 'recrutement' ? 'active' : 'notactive'"><router-link to="/recrutement">Recrutement</router-link></li>
              <li :class="currentPath == 'market-creatif' ? 'active' : 'notactive'"><router-link to="/market-creatif">Marketing Créatif</router-link></li>
              <li :class="currentPath == 'prod-events' ? 'active' : 'notactive'"><router-link to="/prod-events">Production d’événements</router-link></li>
            </ul>
          </li>-->
      <li :class="currentPath == 'recrutement' ? 'active' : 'notactive'"><router-link to="/recrutement">Recrutement</router-link></li>
      <li :class="currentPath == 'market-creatif' ? 'active' : 'notactive'"><router-link to="/market-creatif">Marketing Créatif</router-link></li>
      <li :class="currentPath == 'prod-events' ? 'active' : 'notactive'"><router-link to="/prod-events">Production d’événements</router-link></li>
      <li v-bind:class="{'active': currentPath == `jobs`}">
        <router-link to="/jobs/list">Offres D'emploi</router-link>
      </li>
      <li v-bind:class="{'active': currentPath == 'contact'}">
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
    <ul class="main-nav connect-menu" v-if="isScreenMobile">
      <li v-if="!isLoggedIn" class="nav-item">
        <router-link class="nav-link header-login" to="/login">Se Connecter</router-link>
      </li>
      <li class="nav-item" v-if="!isLoggedIn">
        <router-link class="nav-link header-login add-listing" to="/inscrire" v-if="!isLoggedIn">
          S'inscrire
        </router-link>
      </li>
    </ul>
    <ul class="main-nav connect-menu" v-if="isScreenMobile">
      <li v-if="isLoggedIn" class="nav-item">
        <router-link class="nav-link header-login" to="/dashboard">Tableau de Board</router-link>
        <a href="#" class="nav-link header-login" @click.prevent="logout">Se Déconnecter </a>
      </li>
    </ul>
  </div>
</template>

<script setup>

import {isUserLoggedIn} from "@/router/utils";
import {computed, onMounted, onUnmounted, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {removeData} from "@/plugins/auth";
import { useRouter } from "vue-router";

const router = useRouter()

const isScreenMobile = ref(window.screen.width <= 800)

const isLoggedIn = computed(() => {
  return isUserLoggedIn()
})

const logout = () => {
  axiosIns.post('logout')
      .then((res) => {
        if (res.data.msg){
          removeData()
          router.push({ name: 'Login' })
        }
      })
}

onMounted(() => {
  window.addEventListener("resize", () => {
    isScreenMobile.value = window.screen.width <= 800
  });
})

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    isScreenMobile.value = window.screen.width <= 800
  });
})

</script>

<script>
export default {
  components: {},
  mounted() {
    if ($(window).width() <= 991) {
      var Sidemenu = function () {
        this.$menuItem = $('.main-nav a');
      };

      function init() {
        var $this = Sidemenu;
        $('.main-nav a').on('click', function (e) {

          $('html').removeClass('menu-opened');
          $('.sidebar-overlay').removeClass('opened');
          $('main-wrapper').removeClass('slide-nav');

          if ($(this).parent().hasClass('has-submenu')) {
            e.preventDefault();
          }
          if (!$(this).hasClass('submenu')) {
            $('ul', $(this).parents('ul:first')).slideUp(350);
            $('a', $(this).parents('ul:first')).removeClass('submenu');
            $(this).next('ul').slideDown(350);
            $(this).addClass('submenu');
          } else if ($(this).hasClass('submenu')) {
            $(this).removeClass('submenu');
            $(this).next('ul').slideUp(350);
          }
        });
      }

      init();
    }

  },
  computed: {
    currentPath() {
      return this.$route.name
    },
    expertiseMenu() {
      return this.$route.name == 'recrutement' || this.$route.name == 'market-creatif' || this.$route.name == 'prod-events';
    },
  }
}
</script>

<style>
.connect-menu{
  width: 100%;
  position: absolute;
  bottom: 0;
}
.connect-menu  li {
  text-align: center;
}
</style>