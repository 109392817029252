import Index from "@/views/pages/index/index.vue";
import Contact from "@/views/pages/contact/Contact.vue";
import Dashboard from "@/views/pages/Admin/dashboard/Dashboard.vue";
import Error401 from "@/views/pages/Error/error401/Error401.vue";
import Error404 from "@/views/pages/Error/error404/Error404.vue";
import Error500 from "@/views/pages/Error/error500/Error500.vue";
import ForgotPass from "@/views/pages/forgot-password/Password.vue";
import LoginPage from "@/views/pages/Login/Login.vue";
import Profiles from "@/views/pages/Admin/profile/Profile.vue";
import SignUp from "@/views/pages/signup/Signup.vue";
import indexRecrutement from "@/views/pages/services/recrutement/indexRecrutement.vue";
import indexMarketing from "@/views/pages/services/marketing/indexMarketing.vue";
import indexEvents from "@/views/pages/services/events/indexEvents.vue";
import indexJobs from "@/views/pages/jobs/indexJobs.vue";
import indexPostuler from "@/views/pages/jobs/indexPostuler.vue";
import indexJobDetail from "@/views/pages/jobs/indexJobDetail.vue";
import ACandidates from '@/views/pages/Admin/acandidates'
import AJobs from '@/views/pages/Admin/ajob'
import AStores from '@/views/pages/Admin/astores'
import AUsers from '@/views/pages/Admin/ausers'
import AProfile from '@/views/pages/Admin/profile'
import appliedJobs from '@/views/pages/Admin/appliedJobs'
import AFront from  '@/views/pages/Admin/front'
export default [
    // Rfij
    {
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: '/recrutement',
        name: 'recrutement',
        component: indexRecrutement
    },
    {
        path: '/market-creatif',
        name: 'market-creatif',
        component: indexMarketing
    },
    {
        path: '/prod-events',
        name: 'prod-events',
        component: indexEvents
    },
    {
        path: '/jobs',
        redirect: '/jobs/list',
    },
    {
        path: '/jobs/:q',
        name: 'jobs',
        component: indexJobs
    },
    {
        path: '/job-detail/:id',
        name: 'job-detail',
        component: indexJobDetail,
        props: true
    },
    {
        path: '/inscrire',
        name: 'inscrire',
        component: indexPostuler,
        meta: {
            layoutFull: true,
            redirectIfLoggedIn: true
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },

    //Admin

    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            subject: 'dashboard',
            action: 'read',
        }
    },
    {
        path: '/acandidates',
        name: 'acandidates',
        component: ACandidates,
        meta: {
            subject: 'acandidates',
            action: 'read',
        }
    },
    {
        path: '/ajobs',
        name: 'ajobs',
        component: AJobs,
        meta: {
            subject: 'ajobs',
            action: 'read',
        }
    },
    {
        path: '/applied_jobs',
        name: 'applied_jobs',
        component: appliedJobs,
        meta: {
            subject: 'applied_jobs',
            action: 'read',
        }
    },
    {
        path: '/astores',
        name: 'astores',
        component: AStores,
        meta: {
            subject: 'astores',
            action: 'read',
        }
    },
    {
        path: '/ausers',
        name: 'ausers',
        component: AUsers,
        meta: {
            subject: 'ausers',
            action: 'read',
        }
    },
    {
        path: '/afront',
        name: 'afront',
        component: AFront,
        meta: {
            subject: 'afront',
            action: 'read',
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: AProfile,
        meta: {
            subject: 'profile',
            action: 'read',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
            redirectIfLoggedIn: true,
            layoutFull: true,
        }
    },
    {
        path: '/signup',
        name: 'Signup',
        component: SignUp,
        meta: {
            redirectIfLoggedIn: true,
            layoutFull: true,
        }
    },
    {
        path: '/forgot-password',
        name: 'Password',
        component: ForgotPass,
        meta: {
            redirectIfLoggedIn: true,
            layoutFull: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profiles,
        meta: {
            AuthRequired: true
        }
    },
    {
        path: '/error-401',
        name: 'error-401',
        component: Error401,
        meta: {
            layoutFull: true,
        }
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: Error500,
        meta: {
            layoutFull: true,
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error-404',
        component: Error404,
        meta: {
            layoutFull: true,
        }
    },



]