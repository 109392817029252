import { createStore } from 'vuex'
import services from './modules/services'
import stores from './modules/stores'
import categories from './modules/categories'
import partners from './modules/partners'
import config from './modules/config'
import applicants from './modules/applicants'

export default createStore({
    modules: {
        services,
        stores,
        categories,
        partners,
        config,
        applicants
    },
})