<script setup>
import { onMounted, reactive, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {useStore} from "vuex";
import Form from "vform";

Form.axios = axiosIns

const store = useStore()
const columns = ref([
  {
    title: "Nom Magasin",
    key: "name",
    sorter: true
  },
  {
    title: "Categorie",
    key: "category",
    sorter: true

  },
  {
    title: "Ville",
    key: "city",
    sorter: true

  },
  {
    title: "Region",
    key: "region",
    sorter: true

  },
  {
    title: "Adresse",
    key: "address",
    sorter: true

  },

  {
    title: "Action",
    key: "action",
    class: "text-end",
  },
])
const lastPage = ref()
const tableData = ref()
const total = ref(null)
const loading = ref(false)
let params = reactive({
  page: 1,
  limit: 10,
  filters: null,
  columnKey: null,
  order: null,
  keyword: null,
})

const visible = ref(false)
const cities = ref()
const storesCates = ref()
let form = reactive(new Form({
  id: null,
  name: null,
  category: null,
  city: null,
  region: null,
  address: null,
}))

const handleTableChange = (pag, filters, sorter) => {
  if (sorter.column) {
    params.order = sorter.order
    params.columnKey = sorter.columnKey
  } else {
    params.order = null
  }
  getData()
};

const getData = async () => {
  loading.value = true
  const res = await axiosIns.get('stores', {
    params
  })

  tableData.value = res.data.data
  total.value = res.data.total
  lastPage.value = res.data.last_page;
  params.page = parseInt(res.data.current_page)
  loading.value = false
}

const OpenModel = (item) => {
  visible.value = true
  form.reset()
  form.clear()
  if (item) {
    form.fill(item)
  }
}

const submitData = () => {
  if (form.id) {
    form.put(`stores/${form.id}`).then((res) => {
      if (res.data.msg) {
        visible.value = false
        getData()
      }
    })
  } else {
    form.post('stores').then((res) => {
      if (res.data.msg) {
        visible.value = false
        getData()
      }
    })
  }
}

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= lastPage.value) {
    params.page = pageNumber;
    getData()
  }

}

const deleteItem = (item) => {
  form.delete(`stores/${item.id}`)
  getData()
}
const getCities = async () => {
  const res = await axiosIns.get('model/cities')
  cities.value = res.data
}
const getStoresCat = async () => {
  const res = await axiosIns.get('get-stores-cat')
  storesCates.value = res.data
}

const clearKeyword = () => {
  const keys = Object.getOwnPropertyNames(params)
  keys.forEach(item => {
    params[item] = null
  })
  params.limit = 10
  params.page = 1
  getData()
}

onMounted(() => {
  getData()
  getCities()
  getStoresCat()
  if (store.getters["stores/data"].length === 0) {
    store.dispatch("stores/getData")
  }
})
</script>

<template>
  <div>
    <div class="card-header">

      <div class="d-flex justify-content-between" style="width: 180px">
        <h4>Liste Magasins</h4>
        <div v-if="total" class="badge badge-success rounded-pill mt-0 ">{{ total }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <a class="nav-link header-login add-listing bg-danger" style="margin-right: 10px" @click="clearKeyword"><i
            class="fa-solid fa-filter"></i>
          Supprimer Filter</a>
        <a class="nav-link header-login add-listing bg-info" style="margin-right: 10px"><i
            class="fa-solid fa-download"></i>
          Export</a>
        <a class="nav-link header-login add-listing" style="margin-right: 10px" @click="getData"><i
            class="fa-solid fa-sync"></i>
          Actualiser</a>
        <a class="nav-link header-login add-listing bg-success" @click="OpenModel(null)"><i
            class="fa-solid fa-plus"></i>
          Ajouter</a>

      </div>
    </div>
    <div class="card-body">
      <div class="listing-search">

        <div class="filter-content form-group">
          <a-input-search
              v-model:value="params.keyword"
              placeholder="Recharche par nom"
              allow-clear
              size="large"
              :loading="loading"
              @search="getData"
          />
        </div>
        <div class="sorting-div">
          <div class="sortbyset">
            <a-select
                style="width: 300px; margin-right: 10px"
                placeholder="Choisir Categorie"
                v-model:value="params.category"
                :options="storesCates?.map(item => ({ value: item }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
            <a-select
                style="width: 300px; margin-right: 10px"
                v-model:value="params.city"
                placeholder="Choisir Ville"
                :options="cities?.map(item => ({ value: item.name }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <a-table
            class="stripped table-hover listing-table datatable"
            :columns="columns"
            :loading="loading"
            :data-source="tableData"
            :pagination="false"
            :row-key="record => record.id"
            @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key !== 'action'">
              <span>{{ record[column.key] }}</span>
            </template>
            <template v-else>
              <div class="action">
                <a href="javascript:void(0)" class="action-btn bg-primary text-light" title="Modifier"
                   @click="OpenModel(record)"> <i
                    class="fas fa-edit"></i></a>
                <a-popconfirm title="Supprimer ?" @confirm="deleteItem(record)">
                  <a href="javascript:void(0)" class="action-btn bg-danger text-light" title="Supprimer"> <i
                      class="fas fa-trash"></i></a>
                </a-popconfirm>
              </div>
            </template>
          </template>
        </a-table>
      </div>
      <div class="text-center">
        <a-pagination
            v-model:current="params.page"
            v-model:page-size="params.limit"
            :total="total"
            showQuickJumper
            :show-total="total => `Total ${total} Magasin`"
            @change="goToPage"
        />
      </div>

    </div>
    <a-modal
        v-model:visible="visible"
        title="Candidat"
        width="900px"
        centered
        ok-text="Enregister"
        cancel-text="Férmer"
        @ok="submitData"
    >
      <a-form layout="vertical">
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Nom Magasin" :validateStatus="form.errors.has('name') ? 'error' : ''"
                         :help="form.errors.get('name')">
              <a-input v-model:value="form.name" @input="form.errors.clear('name')"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Categorie" :validateStatus="form.errors.has('category') ? 'error' : ''"
                         :help="form.errors.get('category')">
              <a-select
                  v-model:value="form.category"
                  :options="storesCates?.map(item => ({ value: item }))"
                  @input="form.errors.clear('category')"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Ville" :validateStatus="form.errors.has('city') ? 'error' : ''"
                         :help="form.errors.get('city')">
              <a-select
                  v-model:value="form.city"
                  :options="cities"
                  @input="form.errors.clear('city')"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Region" :validateStatus="form.errors.has('region') ? 'error' : ''"
                         :help="form.errors.get('region')">
              <a-input v-model:value="form.region" @input="form.errors.clear('region')"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Adresse" :validateStatus="form.errors.has('address') ? 'error' : ''"
                         :help="form.errors.get('address')">
              <a-input v-model:value="form.address" @input="form.errors.clear('address')"/>
            </a-form-item>
          </a-col>

        </a-row>
      </a-form>

    </a-modal>
  </div>
</template>

<style>
.disabled:hover {
  cursor: not-allowed !important;
}
</style>
