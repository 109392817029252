<template>
  <div id="app">
    <div v-show="!loading" class="main-wrapper">
      <layouts v-if="!$route.meta.layoutFull && !isPanelRoute"></layouts>
      <template v-if="isScreenMobile">
        <mobile-menu v-if="!$route.meta.layoutFull && isPanelRoute"></mobile-menu>
      </template>
      <template v-else>
        <admin-menu v-if="!$route.meta.layoutFull && isPanelRoute"/>
      </template>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>

      <foot v-if="!$route.meta.layoutFull && !isPanelRoute "/>

      <scroll v-if="!$route.meta.layoutFull && !isPanelRoute"/>

    </div>
    <div v-show="loading" class="loadingio-spinner-interwind-qdg2kcged9">
      <div class="ldio-rgap60b53r center-d">
        <div>
          <div>
            <div>
              <div></div>
            </div>
          </div>
          <div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import mobileMenu from "@/components/mobileMenu.vue"

const store = useStore()
const  route = useRoute()
const isScreenMobile = ref(window.screen.width <= 800)


onBeforeMount(() => {
if (!route.meta.layoutFull ) {
  if (!store.getters["config/getCompaty"]) {
    store.dispatch('config/getData')
  }
}
})
onMounted(() => {
  $('body').append('<div class="sidebar-overlay"></div>');
  setTimeout(() => {
    store.commit('config/SET_LOADING')
  },1500)

  window.addEventListener("resize", () => {
    isScreenMobile.value = window.screen.width <= 800
  });
})

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    isScreenMobile.value = window.screen.width <= 800
  });
})
</script>
<script>

import {isUserLoggedIn} from "@/router/utils";
import menuList from "@/views/pages/Admin/menuList";

export default {
  name: 'App',
  methods: {isUserLoggedIn},

  data() {
    return {}
  },
  computed: {
    loading() {
      return this.$store.getters["config/isLoading"]
    },
    isPanelRoute() {
      return !!menuList.find(item => item.route === this.$route.path)
    }
  },
}


</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: 400ms ease all;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(60px);
}

.page-admin {
  background: #f9fafc;
  padding-top: 80px;
  height: 100vh;
}
</style>

<style>
@keyframes ldio-rgap60b53r-r {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8)
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1)
  }
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@keyframes ldio-rgap60b53r-s {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8)
  }
  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1)
  }
  0% {
    transform: translate(-30px, -30px) scale(0)
  }
  50% {
    transform: translate(-30px, -30px) scale(1)
  }
  100% {
    transform: translate(-30px, -30px) scale(0)
  }
}

.ldio-rgap60b53r > div {
  transform: translate(0px, -15px)
}

.ldio-rgap60b53r > div > div {
  animation: ldio-rgap60b53r-r 1s linear infinite;
  transform-origin: 100px 100px;
}

.ldio-rgap60b53r > div > div > div {
  position: absolute;
  transform: translate(100px, 82px);
}

.ldio-rgap60b53r > div > div > div > div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #203864;
  animation: ldio-rgap60b53r-s 1s linear infinite;
}

.ldio-rgap60b53r > div > div:last-child {
  animation-delay: -0.5s;
}

.ldio-rgap60b53r > div > div:last-child > div > div {
  animation-delay: -0.5s;
  background: #00aee4;
}

.loadingio-spinner-interwind-qdg2kcged9 {
  width: 100%;
  height: 100vh;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
.center-d {
  position: absolute;
  top: 40%;
  left: 47%;
  margin-left: -50px;
  margin-top: -25px;
}

.ldio-rgap60b53r {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-rgap60b53r div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
</style>