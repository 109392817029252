<template>
  <div class="page-wrapper">
    <div class="blogbanner">

      <div class="blogbanner-content">
        <h1>Marketing Créatif</h1>
        <span class="blog-hint">Expertise</span>

      </div>
    </div>
    <!--/Blog Banner-->

    <!--Blog Content-->
    <div class="blogdetail-content">
      <div class="container">
        <div class="card">
          <div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span>
            <h4>Description</h4>
          </div>
          <div class="card-body">
            <p class="">Pour optimiser votre communication, <strong>RFij</strong> met à votre disposition son
              savoir-faire marketing à
              travers une nouvelle offre créative pour imaginer et prendre en charge vos campagnes de communication
              globales.
            </p>
            <p>Nous suivons une méthodologie efficace qui place l’utilisateur final au cœur de nos préoccupations.
              Nous vous accompagnons lors de vos projets étape par étape afin de vous offrir les meilleures
              recommandations en marketing créatif possibles.</p>
          </div>
        </div>
<!--        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <i class="feather-image"></i>
                <h4>Notre Strategies Marketing</h4>
              </div>
              <div class="card-body">
                <img src="@/assets/img/str-mar.png" class="img-fluid" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <i class="feather-image"></i>
                <h4>Processus Marketing RFIJ Agency</h4>
              </div>
              <div class="card-body">
                <img src="@/assets/img/pr-mar.png" class="img-fluid" alt="" style="height: 460px;">
              </div>
            </div>
          </div>
        </div>-->
        <div class="card">
          <div class="card-header">
            <i class="feather-image"></i>
            <h4>Nos Points Forts</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="row row-gap-14">
                  <div class="col-sm-6 col-lg-3" v-for="item in pf" :key="item.id">
                    <div class="shadow-box pf d-flex flex-column">
                      <div class="flex-shrink-0">
                        <div class="icon-blk rounded-circle overflow-hidden" style="border-radius: 0 !important;">
                          <img style="height: 120px;" :src="require(`@/assets/img/pf/${item.image}`)" alt="pf">

                        </div>
                      </div>
                      <div class="flex-grow-1 mt-2" style="margin-block: auto;">
                        <h4>{{ item.country }}</h4>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script setup>
import PF from './points_forts.json'
import {ref} from "vue";

const pf =  ref(PF)

</script>

<style>
.pf{
  border: 1px solid #edededee;
  padding: 10px;
  text-align: center;
}
</style>