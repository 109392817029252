<script setup>
import {onMounted, reactive, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import Form from "vform";
Form.axios = axiosIns

const loading = ref(false)
let form = reactive(new Form({
  id: null,
  address: null,
  email: null,
  phone: null,
  description: null,
  facebook: null,
  linkedin: null,
  twitter: null,
  instagram: null,
}))

const submit = () => {
  loading.value = true

  form.put('config/1').then(res => {
    if (res.data){
      form.fill(res.data.result)
      loading.value = false
    }
  })
}

onMounted(() => {
  loading.value = true

  axiosIns.get('config').then(res => {
    if (res.data){
      form.fill(res.data)
      loading.value = false
    }
  })
})
</script>

<template>
<a-spin :spinning="loading">
  <a-form layout="vertical" class="p-5">
    <a-row :gutter="12">
      <a-col :span="12">
        <a-form-item name="Address" label="Address" :validateStatus="form.errors.has('address') ? 'error' : ''"
                     :help="form.errors.get('address')">
          <a-input v-model:value="form.address" @input="form.errors.clear('address')"/>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item name="email" label="email" :validateStatus="form.errors.has('email') ? 'error' : ''"
                     :help="form.errors.get('email')">
          <a-input v-model:value="form.email" @input="form.errors.clear('email')"/>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="12">
      <a-col :span="12">
        <a-form-item name="Téléphone" label="Téléphone" :validateStatus="form.errors.has('phone') ? 'error' : ''"
                     :help="form.errors.get('phone')">
          <a-input v-model:value="form.phone" @input="form.errors.clear('phone')"/>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item name="Description" label="Description" :validateStatus="form.errors.has('description') ? 'error' : ''"
                     :help="form.errors.get('description')">
          <a-textarea v-model:value="form.description" auto-size @input="form.errors.clear('description')"/>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="12">
      <a-col :span="12">
        <a-form-item name="Facebook" label="Facebook" :validateStatus="form.errors.has('facebook') ? 'error' : ''"
                     :help="form.errors.get('facebook')">
          <a-input v-model:value="form.facebook" @input="form.errors.clear('facebook')"/>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item name="linkedin" label="Linkedin" :validateStatus="form.errors.has('linkedin') ? 'error' : ''"
                     :help="form.errors.get('linkedin')">
          <a-input v-model:value="form.linkedin" @input="form.errors.clear('linkedin')"/>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="12">
      <a-col :span="12">
        <a-form-item name="twitter" label="Twitter" :validateStatus="form.errors.has('twitter') ? 'error' : ''"
                     :help="form.errors.get('twitter')">
          <a-input v-model:value="form.twitter" @input="form.errors.clear('twitter')"/>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item name="instagram" label="Instagram" :validateStatus="form.errors.has('instagram') ? 'error' : ''"
                     :help="form.errors.get('instagram')">
          <a-input v-model:value="form.instagram" @input="form.errors.clear('instagram')"/>
        </a-form-item>
      </a-col>
    </a-row>

    <a-button @click="submit">Enregistrer</a-button>
  </a-form>

</a-spin>
</template>

<style scoped>

</style>