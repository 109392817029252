<template>
  <div class="page-wrapper">
    <breadcrumb title="Offres d'emploi"/>

    <div class="list-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 theiaStickySidebar">
            <div class="stickysidebar">
              <div class="listings-sidebar">
                <div class="card">
                  <h4><img src="@/assets/img/details-icon.svg" alt="details-icon"> Filter</h4>
                  <b-form>
                    <div class="filter-content looking-input form-group">
                      <b-form-input type="text" class="form-control" v-model="params.keyword"
                                    placeholder="Recherche...."/>
                    </div>
                    <div class="filter-content form-group region">
                      <vue-select
                          :options="stores?.map(item => item.name)"
                          placeholder="Magasin"
                          v-model="params.store"
                      />
                    </div>
                    <div class="filter-content form-group region">
                      <vue-select
                          :options="cities?.map(item => item.name)"
                          placeholder="Ville"
                          v-model="params.city"
                      />
                    </div>
                    <div class="filter-content form-group amenities">
                      <h4> Categories</h4>
                      <b-form-group v-slot="{ ariaDescribedby }">
<!--                        <b-form-radio-group-->
<!--                            v-model="params.category"-->
<!--                            :options="categories.map(item => ({value: item.title, text: item.title}))"-->
<!--                            :aria-describedby="ariaDescribedby"-->
<!--                        >-->
<!--                          <template #default>-->
<!--                            test-->
<!--                          </template>-->
<!--                        </b-form-radio-group>-->
                        <b-form-radio v-for="item of categories" :key="item.id" v-model="params.category"
                                      :aria-describedby="ariaDescribedby" :value="item.title">{{ item.title }} ({{item.jobs_count}})</b-form-radio>

                      </b-form-group>
                    </div>
                    <div class="filter-content amenities mb-0">
                      <div class="search-btn">
                        <b-button variant="primary" @click="getJobs"><i class="fa fa-search" aria-hidden="true"></i>
                          Recherche
                        </b-button>
                        <b-button variant="reset mb-0 bg-danger text-white" @click="clearParams"><i
                            class="fas fa-light fa-arrow-rotate-right"></i>
                          Suprimmer Le Filter
                        </b-button>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="row sorting-div">
              <div class="col-lg-4 col-sm-4 align-items-center d-flex">
                <div class="count-search">
                  <p>Affichage <span>{{ jobs.length }}</span> De <span>{{ total }}</span> Offres</p>
                </div>
              </div>
              <div class="col-lg-8 col-sm-8  align-items-center">
                <div class="sortbyset">
                  <span class="sortbytitle">Trier Par</span>
                  <div class="sorting-select">
                    <vue-select
                        :options="sortOptions"
                        v-model="params.sortBy"
                        @update:modelValue="changeLimit"
                        placeholder="Trier"/>
                  </div>
                  <div style="width: 60px; margin-left: 10px">
                    <vue-select
                        :options="[5,10,20,30,40]"
                        v-model="params.limit"
                        @update:modelValue="getJobs"
                        placeholder="Trier"/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <a-spin :spinning="loading">
                <div class="col-md-12 mb-2" v-for="item of jobs" :key="item.id">
                  <div class="job-card" :class="{'p-job': resolvePostuledJobs(item.id)}">
                    <div class="job-card__content">
                      <div class="job-card_img">
                        <img :src="require(`@/assets/api/jobs/${item.image}`)" alt="Company Logo">
                      </div>
                      <div class="job-card_info">
                        <h6 class="text-muted">
                          <a href="#!" class="job-card_company"><i class="feather-bookmark"></i>
                            {{ item.category }}</a>
                          <div class="float-end d-flex flex-column justify-content-between job-actions"
                               style="height: 100px">
                            <a-popconfirm title="Confirmer ?" @confirm="postuler(item)">
                              <button class="btn" style="background-color: #00aee4; color: white">Postuler</button>
                            </a-popconfirm>
                            <button class="btn" @click="jobDetail(item)"
                                    style="background-color: #203864; color: white;">Afficher
                            </button>
                          </div>
                        </h6>
                        <a href="#" @click.prevent="jobDetail(item)"><h4>{{ item.title }}</h4></a>
                        <p class="mb-0">
                          <i class="feather-map-pin"></i>
                          {{ item.store }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-2" v-if="jobs.length === 0">
                  <div class="job-card">
                    <div class="job-card__content">
                      <div v-if="loading" class="job-card_info d-flex flex-column text-center">
                        <strong class="mb-3">Chargement en cours...</strong>

                      </div>
                      <div v-else class="job-card_info d-flex flex-column text-center">
                        <strong class="mb-3">Aucune Offre Disponible</strong>
                        <button class="btn bg-danger" style=" color: white" @click="clearParams">Supprimer le Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-pagination" v-if="jobs.length > 0">
                  <nav>
                    <ul class="pagination">
                      <li class="page-item previtem" :class="{disabled: params.page <= lastPage}">
                        <a class="page-link" href="" @click.prevent="goToPage(params.page - 1)">
                          <i class="fas fa-regular fa-arrow-left"></i> Préc</a>
                      </li>
                      <li class="justify-content-center pagination-center">
                        <div class="pagelink">
                          <ul>
                            <li class="page-item" v-for="item in lastPage" :key="item"
                                :class="{ 'active': item === params.page }">
                              <a class="page-link" href="#" @click.prevent="goToPage(item)">{{ item }}</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="page-item nextlink" :class="{disabled: params.page < 1 || params.page === lastPage}">
                        <a class="page-link" href="#" @click.prevent="goToPage(params.page + 1)">Suiv <i
                            class="fas fa-regular fa-arrow-right"></i></a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </a-spin>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import AxiosIns from "@/plugins/axiosIns";
import {computed, onBeforeMount, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {message} from "ant-design-vue";

import {isUserLoggedIn} from "@/router/utils";
import axiosIns from "@/plugins/axiosIns";
import {useStore} from "vuex";

const router = useRouter()
const route = useRoute()
const myStore = useStore()

const jobs = ref([])
const categories = computed(() => myStore.getters["categories/data"])
const jobss = computed(() => jobs.value)
const cities = ref([])
const stores = ref([])
const appliedJobsIds = ref([])
const loading = ref(false)
const lastPage = ref()
const total = ref()
const sortOptions = ref(["Date", "Categorie", "Magasin", "Ville"])
let params = reactive({
  sortBy: 'Date',
  limit: 5,
  page: 1
})

const getJobs = () => {
  loading.value = true
   AxiosIns.get('get-jobs', {params}).then(res => {
    jobs.value = res.data.data
    total.value = res.data.total
    lastPage.value = res.data.last_page
    params.page = res.data.current_page
    loading.value = false
  })

}

const getCities = async () => {
  const res = await AxiosIns.get('model/cities')
  cities.value = res.data
}

const getStores = async () => {
  const res = await AxiosIns.get('model/stores')
  stores.value = res.data
}

const goToPage = (page) => {
  params.page = page
  getJobs()
}

const changeLimit = (page) => {
  params.page = 1
  getJobs()
}

const jobDetail = (item) => {
  router.push({
    name: "job-detail",
    props: {item},
    params: {id: item.id}
  });
}

const clearParams = () => {
  params.keyword = null
  params.store = null
  params.category = null
  params.city = null
  params.page = 1
  params.limit = 5
  params.sortBy = "Date"
  getJobs()
}

const postuler = async (item) => {
  const isLogged = isUserLoggedIn()
  if (!isLogged) {
    return message.info('Vous devez Conneter pour Postuler')
  }

  const res = await axiosIns.post('postule', {id: item.id})
  if(res.data.msg){
    await getJobs()
  }
}

const resolveRouteParams = () => {
  const routeParams = route.params.q

  try {
    if (JSON.parse(routeParams) && !!routeParams) {
      return JSON.parse(routeParams)
    }
  } catch (e) {
    return false;
  }
}

const resolvePostuledJobs = jobId => !(appliedJobsIds.value.indexOf(jobId) === -1)

onMounted(async () => {
  const data = resolveRouteParams() != false ? resolveRouteParams() : null
  if (data) {
    params.category = data.category
    params.store = data.store
  }
  await getJobs()

  const isLogged = isUserLoggedIn()
  if (isLogged) {
    const res = await axiosIns.get('get-applicant-jobs_ids')
    appliedJobsIds.value = res.data
  }
  await getCities ()
  await getStores()
})

onBeforeMount(() => {
  if (categories.value.length === 0) {
    myStore.dispatch("categories/getData")
  }
})
</script>


<style>

.job-card {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.job-card__content {
  display: flex;
  align-items: center;
}

.job-card__content .job-card_img {
}

.job-card__content .job-card_img img {
  height: 135px;
  width: 150px;
  object-fit: contain;
  border-radius: 8px;
}

.job-card__content .job-card_info {
  flex: 1;
}

.job-card__content .job-card_info .text-muted a {
  color: #777;
}

.job-card__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-label {
  display: inline-block;
  padding: 6px 12px;
  background: #f5f5f5;
  border-radius: 5px;
  margin-right: 2px;
}

.p-job {
  background: #e0e9e1;
}

</style>
<style src="@vueform/slider/themes/default.css"></style>