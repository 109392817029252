<script setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {getCryptedData, removeData, setData} from "@/plugins/auth";
import {useRoute, useRouter} from "vue-router";
import {canViewNavMenu} from "@/plugins/casl/gate";
import menuList from "@/views/pages/Admin/menuList";


const router = useRouter()
const route = useRoute()

watch(route, async () => {
  const res = await axiosIns.get('user-abilities')
  setData(res.data)
})

const user = computed(() => {
  return getCryptedData('user') ?? null
})

const logout = () => {
  myFunction()
  axiosIns.post('logout')
      .then((res) => {
        if (res.data.msg) {
          removeData()
          router.push({name: 'Login'})
        }
      })
}

const listItems = ref(menuList)
const myFunction = () => {
  const x = document.getElementById("myLinks");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}

</script>

<template>
  <div class="topnav">
    <div class="userdrop">
      <img src="@/assets/img/profile-img.jpg" alt="">
      <h5 class="d-inline-block" style="margin-left: 10px;margin-bottom: 0px">{{ user?.username }}</h5>
    </div>
    <div id="myLinks">
      <ul class="">
        <div class="">
          <template v-for="(item, index) of listItems" :key="index">
            <li v-if="canViewNavMenu(item)" @click="myFunction" :class="$route.path === item.route ? 'active' : 'inactive'">
              <router-link :to="item.route">
                <i :class="item.icon"></i> <span>{{ item.title }}</span>
              </router-link>
            </li>
          </template>
          <li><router-link class="dropdown-item" to="/" @click="myFunction"><i class="feather-monitor"></i>Page D'accueil</router-link></li>
          <li>
            <a href="#" class="dropdown-item" @click.prevent="logout"><i class="feather-log-out"></i>Se Déconnecter</a>
          </li>
        </div>
      </ul>
    </div>
    <a href="javascript:void(0);" class="icon" @click="myFunction()">
      <i class="fa fa-bars"></i>
    </a>
  </div>
</template>

<style scoped>
/* Style the navigation menu */
.topnav {
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: white;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #myLinks {
  display: none;
  height: 100vh;
}

/* Style navigation menu links */
.topnav a {
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

/* Style the hamburger menu */
.topnav a.icon {
  background: white;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active link (or home/logo) */
.active {
  color: #00aee4;
}

.userdrop{
  box-shadow: 0 8px 8px -4px #c0c7c9;
}

.userdrop img {
  height: 54px;
  border-radius: 50px;
  margin-left: 10px;
  padding: 4px;
}
.topnav i {
  margin-right: 8px
}


</style>