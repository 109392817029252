import {createApp} from 'vue';
import router from './router';
import App from "./App.vue";
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import VueApexCharts from "vue3-apexcharts";
import VueSelect from 'vue3-select2-component'
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueEasyLightbox from "vue-easy-lightbox";
import Antd from 'ant-design-vue';
import AOS from 'aos'
import VueCarousel from 'vue-carousel';


// Header Components
import NavMenu from './components/navmenu.vue'
import Navbar from './components/navbar.vue'


// Page Components
import Header from './views/layouts/header.vue'
import IndexExplore from './views/pages/index/IndexExplore.vue'
import IndexPartners from './views/pages/index/IndexPartners.vue'
import Foot from './views/pages/Footer.vue'
import Scroll from './views/pages/Scroll.vue'
import ContactInfo from './views/pages/contact/ContactInformation.vue'
import Dashboard from '@/views/pages/Admin/dashboard/DashboardContent.vue'
import ForgotPassword from './views/pages/forgot-password/ForgotPassword.vue'
import LoginForm from './views/pages/Login/LoginForm.vue'
import PrivacyPolicy from './views/pages/privacy-policy/PrivacyTerms.vue'
import BreadCrumb from './components/breadcrumb/Component.vue'


// plugins
// import './assets/js/map.js'
import 'aos/dist/aos.css'
import './assets/js/backToTop.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'ant-design-vue/dist/antd.css';
import './assets/css/feather.css';
import './assets/css/style.css';
import store from "@/store";
import IndexServices from "@/views/pages/index/IndexServices.vue";
import indexHowItWork from "@/views/pages/index/indexHowItWork.vue";
import adminMenu from "@/views/pages/Admin/adminMenu.vue";
import vSelect from 'vue-select'
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {abilitiesPlugin} from '@casl/vue';
import ability from '@/plugins/casl/ability';

const app = createApp(App)

app.component('vSelect', vSelect)
app.component('layouts', Header)
app.component('navbar', Navbar)
app.component('foot', Foot)
app.component('indexservices', IndexServices)
app.component('indexhowitwork', indexHowItWork)
app.component('indexpartners', IndexPartners)
app.component('indexexplore', IndexExplore)
app.component('navmenu', NavMenu)
app.component('admin-menu', adminMenu)
app.component('scroll', Scroll)
app.component('contactinformation', ContactInfo)
app.component('dashboardcontent', Dashboard)
app.component('forgotpassword', ForgotPassword)
app.component('loginform', LoginForm)
app.component('privacypolicy', PrivacyPolicy)
app.component('breadcrumb', BreadCrumb)
app.component('vue-select', VueSelect)






    .component('QuillEditor', QuillEditor)
    .use(abilitiesPlugin, ability, {
        useGlobalProperties: true
    })
    .use(BootstrapVue3)
    .use(BToastPlugin)
    .use(Antd)
    .use(AOS.init())
app.use(VueEasyLightbox);
app.use(CoolLightBox);
app.use(VueApexCharts);
app.use(VueCarousel);
app.use(store)
app.use(router).mount('#app');