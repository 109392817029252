<template>

  <div class="page-wrapper">
    <indexexplore/>
    <indexservices/>
    <indexhowitwork class=" hidden-sm"/>
    <indexpartners/>
  </div>

</template>

<script setup>
import {useStore} from "vuex";
import {onBeforeMount} from "vue";

const myStore = useStore()

onBeforeMount(() => {
  if (myStore.getters["categories/data"].length === 0) {
    myStore.dispatch("categories/getData")
  }
  if (myStore.getters["services/data"].length === 0) {
    myStore.dispatch("services/getData")
  }
  if (myStore.getters["stores/data"].length === 0) {
    myStore.dispatch("stores/getData")
  }
  if (myStore.getters["partners/data"].length === 0) {
    myStore.dispatch("partners/getData")
  }
})
</script>