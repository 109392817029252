<template>

        <div class="page-wrapper">

            <forgotpassword  />


        </div>

</template>

<script>
export default {
    data() {
        return {
            title: "Forgot Password",
            text: "Home",
            text1: "Forgot Password",
            name: "/",
        }
    },
}
</script>