<script setup>
import {onMounted, reactive, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {useStore} from "vuex";
import Form from "vform";
Form.axios = axiosIns

import multiCheckbox from "@/components/Jcheckbox/multiCheckbox.vue";

const store = useStore()
const columns = ref([
  {
    title: "Utilisateur",
    key: "username",
    sorter: true
  },
  {
    title: "Categorie",
    key: "email",
    sorter: true

  },
  {
    title: "Email Verfié",
    key: "email_verified",
    sorter: true

  },
  {
    title: "Bloqué",
    key: "blocked",
    sorter: true

  },
  {
    title: "Role",
    key: "role",
  },

  {
    title: "Action",
    key: "action",
    class: "text-end",
  },
])
const lastPage = ref()
const tableData = ref()
const total = ref(0)
const loading = ref(false)

const roles = ref([
  {label: 'Manager', value: 'manager'},
  {label: 'Candidate', value: 'candidate'}
])

let params = reactive({
  page: 1,
  limit: 10,
  columnKey: null,
  order: null,
  keyword: null,
})

const visible = ref(false)
const visible2 = ref(false)
const cities = ref()
const storesCates = ref()
let form = reactive(new Form({
  id: null,
  username: null,
  email: null,
  email_verified: null,
  blocked: null,
  password: null,
  role: null,
}))

const permissions = ref([])
const checkedKeys = ref([])

const handleTableChange = (pag, filters, sorter) => {
  if (sorter.column) {
    params.order = sorter.order
    params.columnKey = sorter.columnKey
  } else {
    params.order = null
  }
  getData()
};

const getData = async () => {
  loading.value = true
  const res = await axiosIns.get('users', {
    params
  })

  tableData.value = res.data.data
  total.value = res.data.total
  lastPage.value = res.data.last_page;
  params.page = res.data.current_page
  params.limit = res.data.per_page

  loading.value = false
}

const OpenModel = (item) => {
  visible.value = true
  form.reset()
  form.clear()
  if (item) {
    form.fill(item)
    form.role = item.roles[0]?.name
  }
}
const OpenModelPerms = async (item) => {
  visible2.value = true
  const res = await axiosIns.post('role-perms/' + item.roles[0].name)
  checkedKeys.value = res.data.map(item => (`${item.action},${item.subject}`))
}

const submitData = () => {
  if (form.id) {
    form.put(`users/${form.id}`).then((res) => {
      if (res.data.msg) {
        visible.value = false
        getData()
      }
    })
  } else {
    form.post('users').then((res) => {
      if (res.data.msg) {
        visible.value = false
        getData()
      }
    })
  }
}

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= lastPage.value) {
    params.page = pageNumber;
    getData()
  }
}

const deleteItem = (item) => {
  form.delete(`users/${item.id}`)
  getData()
}
const getCities = async () => {
  const res = await axiosIns.get('model/cities')
  cities.value = res.data
}
const getStoresCat = async () => {
  const res = await axiosIns.get('get-stores-cat')
  storesCates.value = res.data
}

const clearKeyword = () => {
  const keys = Object.getOwnPropertyNames(params)
  keys.forEach(item => {
    params[item] = null
  })
  params.limit = 10
  params.page = 1
  getData()
}

const resolveBlocked = (state) => {
  if (state === 1) {
    return 'Bloqué'
  } else {
    return 'Non'
  }
}
const resolveEmailV = (state) => {
  if (state === 0) {
    return 'Non Vérifier'
  } else {
    return 'Vérifier'
  }
}

const resolvePerm = (perms) => {
  return perms.map(item => ({name: item.name, id: `${item.action},${item.subject}`}))
}

const submitPerms = () => {
  console.log(checkedKeys.value);
}

const resolvPermGroup = (name) => {
  if(name === 'ausers')  return 'Utilisateurs'
  else if(name === 'astores')  return 'Magasins'
  else if(name === 'ajobs')  return "Offres d'emploi"
  else if(name === 'acandidates')  return 'Candidates'
  else if(name === 'manager_dashboard')  return 'Manager Dashoard'
  else if(name === 'dashboard')  return 'Dashoard'
  else if(name === 'applied_jobs')  return 'Offres Postuler'
  else if(name === 'profile')  return 'Profile'
  else return ''
}

onMounted(async () => {
  await getData()
  await getCities()
  await getStoresCat()
  if (store.getters["stores/data"].length === 0) {
    await store.dispatch("stores/getData")
  }

  const res = await axiosIns.get('perms')

  Object.keys(res.data).forEach(key => {
    permissions.value.push({
      name: resolvPermGroup(key),
      children: resolvePerm(res.data[key])
    })
  })

})
</script>

<template>
  <div class="dash-cards card">
    <div class="card-header">

      <div class="d-flex justify-content-between" style="width: 180px">
        <h4>Liste Magasins</h4>
        <div v-if="total" class="badge badge-success rounded-pill mt-0 ">{{ total }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <a class="nav-link header-login add-listing bg-danger" style="margin-right: 10px" @click="clearKeyword"><i
            class="fa-solid fa-filter"></i>
          Supprimer Filter</a>
        <a class="nav-link header-login add-listing bg-info" style="margin-right: 10px"><i
            class="fa-solid fa-download"></i>
          Export</a>
        <a class="nav-link header-login add-listing" style="margin-right: 10px" @click="getData"><i
            class="fa-solid fa-sync"></i>
          Actualiser</a>
        <a class="nav-link header-login add-listing bg-success" @click="OpenModel(null)"><i
            class="fa-solid fa-plus"></i>
          Ajouter</a>

      </div>
    </div>
    <div class="card-body">
      <div class="listing-search">

        <div class="filter-content form-group">
          <a-input-search
              v-model:value="params.keyword"
              placeholder="Recharche par nom"
              allow-clear
              size="large"
              :loading="loading"
              @search="getData"
          />
        </div>
        <div class="sorting-div">
          <div class="sortbyset">
            <a-select
                style="width: 150px; margin-right: 10px"
                placeholder="Bloqué?"
                v-model:value="params.blocked"
                :options="[{label: 'Non', value: 0},{label: 'Oui', value: 1}]"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>

            <a-select
                style="width: 150px; margin-right: 10px"
                v-model:value="params.email_verified"
                placeholder="Verifié?"
                :options="[{label: 'Non', value: 0},{label: 'Oui', value: 1}]"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
            <a-select
                style="width: 150px; margin-right: 10px"
                v-model:value="params.role"
                placeholder="Role?"
                :options="roles"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <a-table
            class="stripped table-hover listing-table datatable"
            :columns="columns"
            :loading="loading"
            :data-source="tableData"
            :pagination="false"
            :row-key="record => record.id"
            @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">

            <template v-if="column.key === 'blocked'">
              <a-tag :color="record.blocked === 0 ? 'green' : 'red' ">{{ resolveBlocked(record.blocked) }}</a-tag>
            </template>
            <template v-else-if="column.key === 'email_verified'">
              <a-tag :color="record.email_verified === 1 ? 'green' : 'red' ">{{
                  resolveEmailV(record.email_verified)
                }}
              </a-tag>
            </template>
            <template v-else-if="column.key === 'role'">
              <a-tag>{{ record.roles[0]?.name.charAt(0).toUpperCase() + record.roles[0]?.name.slice(1) }}</a-tag>
            </template>
            <template v-else-if="column.key === 'action'">
              <div class="action">
                <a href="javascript:void(0)" class="action-btn bg-primary text-light" title="Modifier"
                   @click="OpenModel(record)"> <i
                    class="fas fa-edit"></i></a>
                <a v-if="$can('manage', 'all')" href="javascript:void(0)" class="action-btn bg-warning text-light" title="Permissions"
                   @click="OpenModelPerms(record)"> <i
                    class="fas fa-key"></i></a>
                <a-popconfirm title="Supprimer ?" @confirm="deleteItem(record)">
                  <a href="javascript:void(0)" class="action-btn bg-danger text-light" title="Supprimer"> <i
                      class="fas fa-trash"></i></a>
                </a-popconfirm>
              </div>
            </template>

            <template v-else>
              <span>{{ record[column.key] }}</span>
            </template>
          </template>
        </a-table>
      </div>
      <div class="text-center">
        <a-pagination
            v-model:current="params.page"
            v-model:page-size="params.limit"
            :total="total"
            showQuickJumper
            :disabled="loading"
            :show-total="total => `Total ${total} Magasin`"
            @change="goToPage"
        />
      </div>

    </div>
    <a-modal
        v-model:visible="visible"
        title="Utilisateur"
        width="900px"
        centered
        ok-text="Enregister"
        cancel-text="Férmer"
        @ok="submitData"
    >
      <a-form layout="vertical">
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Utilisateur" :validateStatus="form.errors.has('username') ? 'error' : ''"
                         :help="form.errors.get('username')">
              <a-input v-model:value="form.username" @input="form.errors.clear('username')"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Email" :validateStatus="form.errors.has('email') ? 'error' : ''"
                         :help="form.errors.get('email')">
              <a-input v-model:value="form.email" @input="form.errors.clear('email')"/>

            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Mot de Passe" :validateStatus="form.errors.has('password') ? 'error' : ''"
                         :help="form.errors.get('password')">
              <a-input v-model:value="form.password" @input="form.errors.clear('password')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Bloqué?" :validateStatus="form.errors.has('blocked') ? 'error' : ''"
                         :help="form.errors.get('blocked')">
              <a-select
                  v-model:value="form.blocked"
                  :options="[{label: 'Non', value: 0},{label: 'Oui', value: 1}]"
                  @input="form.errors.clear('blocked')"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Role?" :validateStatus="form.errors.has('role') ? 'error' : ''"
                         :help="form.errors.get('role')">
              <a-select
                  v-model:value="form.role"
                  :options="roles"
                  @input="form.errors.clear('role')"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>

    <a-modal
        v-model:visible="visible2"
        title="Permissions"
        width="1000px"
        centered
        ok-text="Enregister"
        cancel-text="Férmer"
        @ok="submitPerms"
    >
      <div class="d-flex justify-content-between w-100 flex-wrap">
        <div v-for="(item, index) of permissions" :key="index" style="border: 1px solid #ededed; padding: 5px; margin-bottom: 10px">
          <span class="mb-3">{{item.name}}</span>
          <a-divider style="margin: 7px 0;"/>
          <multi-checkbox v-model:value="checkedKeys" :options="item.children" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

