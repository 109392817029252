<template>
  <input
      @input="(event) => $emit('update:checked', event.target.checked)"
      type="checkbox"
      :checked="checked"
      :id="fieldId"
      class="mr-2" style="display:none"
  />
  <label
      :for="fieldId"
      class="flex flex-row items-center font-bold text-gray-800 cursor-pointer select-none"
  >
    <i
        class="fa mr-2"
        :class="{
        'fa-check-square checked-b': checked,
        'fa-square unchecked-b': !checked,
      }"
    ></i>
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    fieldId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
  },
};
</script>

<style>
.unchecked-b{
  color: #b1adb3 !important;
}
.checked-b{
  color: #203864 !important;
}
</style>
