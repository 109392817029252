import axiosIns from "@/plugins/axiosIns";

export default {
    namespaced: true,
    state: {
        data: [],
        loading: false
    },
    getters: {
        data: state => state.data,
        loading: state => state.loading,
    },
    actions: {
        async getData({commit }) {
            commit('SET_LOADING', true);
            const res = await axiosIns.get('applicants');
            commit('SET_DATA', res.data);
            commit('SET_LOADING', false);
        },
    },
    mutations: {
        SET_DATA(state, data) {
            state.data = data;
        },
        SET_LOADING(state, data) {
            state.loading = data;
        },
    }
}