<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {useStore} from "vuex";
import Form from "vform";
Form.axios = axiosIns

const store = useStore()
const columns = ref([
  {
    title: "Titre",
    key: "title",
    sorter: true
  },
  {
    title: "Employer",
    key: "employer",
    sorter: true
  },
  {
    title: "Categorie",
    key: "category",
    sorter: true
  },
  {
    title: "Ville",
    key: "city",
    sorter: true
  },
  {
    title: "Magasin",
    key: "store",
    sorter: true
  },
  {
    title: "Etat",
    key: "state",
    sorter: true,
    isCustom: true
  },
  {
    title: "Crée Par",
    key: "created_by",
    sorter: true
  },
  {
    title: "Action",
    key: "action",
    class: "text-end",
    isCustom: true
  },
])
const lastPage = ref()
const tableData = ref()
const total = ref(null)
const loading = ref(false)
const storesNames = computed(() => store.getters["stores/data"])

const jobStates = ref([
  {label: 'Publié', value: 'published'},
  {label: 'Brouillon', value: 'draft'},
  {label: 'Verrouillé', value: 'locked'}
])
let params = reactive({
  page: 1,
  limit: 10,
  filters: null,
  columnKey: null,
  order: null,
  keyword: null,
})

const visible = ref(false)
const categories = ref()
const cities = ref()

let form = reactive(new Form({
  id: null,
  title: null,
  employer: null,
  image: 'default.png',
  category: null,
  city: null,
  store: null,
  description: null,
  missions: null,
  state: null,
}))

const handleTableChange = (pag, filters, sorter) => {


  if (sorter.column) {
    params.order = sorter.order
    params.columnKey = sorter.columnKey
  } else {
    params.order = null
  }
  getData()
};

const getData = async () => {
  loading.value = true
  const res = await axiosIns.get('jobs', {
    params
  })

  tableData.value = res.data.data
  total.value = res.data.total
  lastPage.value = res.data.last_page;
  params.page = res.data.current_page
  loading.value = false
}

const OpenModel = (item) => {

  visible.value = true
  form.reset()
  form.clear()
  if (item){
    form.fill(item)
  }
  else clearQuillBoxes()
}

const submitData = () => {
  if (form.id) {
    form.put(`jobs/${form.id}`).then((res) => {
      if (res.data.msg){
        visible.value = false
        getData()
      }
    })
  }
  else{
    form.post('jobs').then((res) => {
      if (res.data.msg){
        visible.value = false
        getData()
      }
    })
  }
}

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= lastPage.value) {
    params.page = pageNumber;
    getData()
  }
}

const deleteItem = (item) => {
  form.delete(`jobs/${item.id}`)
  getData()
}


const getCities = async () => {
  const res = await axiosIns.get('model/categories')
  categories.value = res.data
}
const getCategories = async () => {
  const res = await axiosIns.get('model/cities')
  cities.value = res.data
}

const clearKeyword = () => {
  const keys = Object.getOwnPropertyNames(params)
  keys.forEach(item => {
    params[item] = null
  })
  params.limit = 10
  params.page = 1
  getData()
}

const resolveState = (state) => {
  if(state === 'published'){
    return 'Publié'
  }else if(state === 'draft'){
    return 'Brouillon'
  } else if(state === 'locked'){
    return  'Verrouillé'
  }
}

const clearQuillBoxes = () => {
 let e = document.getElementsByClassName('ql-editor');
  for(var i = 0; i < e.length; i++) {
    if(e[i].className == 'ql-editor') {
      e[i].innerHTML = ''
    }
  }
}

onMounted(() => {
  getData()
  getCities()
  getCategories()
  if (store.getters["stores/data"].length === 0) {
    store.dispatch("stores/getData")
  }

})
</script>

<template>
  <div class="dash-cards card">
    <div class="card-header">

      <div class="d-flex justify-content-between" style="width: 240px">
        <h4>Liste Offres d'emploi</h4>
        <div v-if="total" class="badge badge-success rounded-pill mt-0 ">{{ total }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <a class="nav-link header-login add-listing bg-danger" style="margin-right: 10px" @click="clearKeyword"><i
            class="fa-solid fa-filter"></i>
          Supprimer Filter</a>
        <a class="nav-link header-login add-listing bg-info" style="margin-right: 10px"><i
            class="fa-solid fa-download"></i>
          Export</a>
        <a class="nav-link header-login add-listing" style="margin-right: 10px" @click="getData"><i
            class="fa-solid fa-sync"></i>
          Actualiser</a>
                <a class="nav-link header-login add-listing bg-success" @click="OpenModel(null)"><i
                    class="fa-solid fa-plus"></i>
                  Ajouter</a>
      </div>
    </div>
    <div class="card-body">
      <div class="listing-search">
        <div class="filter-content form-group">
          <a-input-search
              v-model:value="params.keyword"
              placeholder="Recharche par Titre"
              allow-clear
              size="large"
              :loading="loading"
              @search="getData"
          />
        </div>
        <div class="sorting-div">
          <div class="sortbyset">
            <a-select
                style="width: 150px; margin-right: 10px"
                placeholder="Choisir Statut"
                v-model:value="params.state"
                :options="jobStates"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>

            <a-select
                style="width: 300px; margin-right: 10px"
                placeholder="Choisir Magasin"
                v-model:value="params.store"
                :options="storesNames?.map(item => ({ value: item.name }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
            <a-select
                style="width: 200px; margin-right: 10px"
                placeholder="Choisir Categorie"
                v-model:value="params.category"
                :options="categories?.map(item => ({ value: item.title }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
            <a-select
                style="width: 200px; margin-right: 10px"
                v-model:value="params.city"
                placeholder="Choisir Ville"
                :options="cities?.map(item => ({ value: item.name }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>

          </div>
        </div>
      </div>
      <div class="table-responsive">
        <a-table
            class="stripped table-hover listing-table datatable"
            :columns="columns"
            :loading="loading"
            :data-source="tableData"
            :pagination="false"
            :row-key="record => record.id"
            @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <div class="action">
                <a href="javascript:void(0)" class="action-btn bg-primary text-light" title="Modifier" @click="OpenModel(record)"> <i
                    class="fas fa-edit"></i></a>
                <a-popconfirm title="Supprimer ?" @confirm="deleteItem(record)" >
                  <a href="javascript:void(0)" class="action-btn bg-danger text-light" title="Supprimer"> <i
                      class="fas fa-trash"></i></a>
                </a-popconfirm>
              </div>
            </template>
            <template v-if="column.key === 'state'">
              <span>{{  resolveState(record[column.key]) }}</span>
            </template>
            <template v-else>
              <span>{{ record[column.key] }}</span>
            </template>
          </template>
        </a-table>
      </div>
      <div class="text-center">
        <a-pagination
            v-model:current="params.page"
            v-model:page-size="params.limit"
            :total="total"
            showQuickJumper
            :show-total="total => `Total ${total} Offres d'emploi`"
            @change="goToPage"
        />
      </div>
    </div>
    <a-modal
        v-model:visible="visible"
        title="Offre D'emploi"
        width="900px"
        centered
        ok-text="Enregister"
        cancel-text="Férmer"
        @ok="submitData"
    >
      <a-form layout="vertical">
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Titre" :validateStatus="form.errors.has('title') ? 'error' : ''"
                         :help="form.errors.get('title')">
              <a-input v-model:value="form.title" @input="form.errors.clear('title')"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Employer" :validateStatus="form.errors.has('employer') ? 'error' : ''"
                         :help="form.errors.get('employer')">
              <a-input v-model:value="form.employer" @input="form.errors.clear('employer')"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Categorie" :validateStatus="form.errors.has('category') ? 'error' : ''"
                         :help="form.errors.get('category')">
              <a-select
                  v-model:value="form.category"
                  :options="categories"
                  :field-names="{ label: 'title', value: 'title' }"
                  @input="form.errors.clear('category')"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Magasin" :validateStatus="form.errors.has('store') ? 'error' : ''"
                         :help="form.errors.get('store')">
              <a-select
                  v-model:value="form.store"
                  :options="storesNames"
                  :field-names="{ label: 'name', value: 'name' }"
                  @input="form.errors.clear('store')"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Ville" :validateStatus="form.errors.has('city') ? 'error' : ''"
                         :help="form.errors.get('city')">
              <a-select
                  v-model:value="form.city"
                  :options="cities"
                  :field-names="{ label: 'name', value: 'name' }"
                  @input="form.errors.clear('city')"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Etat" :validateStatus="form.errors.has('state') ? 'error' : ''"
                         :help="form.errors.get('state')">
              <a-select
                  v-model:value="form.state"
                  :options="jobStates"
                  :field-names="{ label: 'label', value: 'value' }"
                  @input="form.errors.clear('state')"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item name="title" label="Description" :validateStatus="form.errors.has('description') ? 'error' : ''"
                         :help="form.errors.get('description')">
              <QuillEditor ref="quillDes" theme="snow" toolbar="minimal" v-model:content="form.description" content-type="html" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="title" label="Missions" :validateStatus="form.errors.has('missions') ? 'error' : ''"
                         :help="form.errors.get('missions')">
              <QuillEditor theme="snow" toolbar="minimal" v-model:content="form.missions" content-type="html" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

    </a-modal>
  </div>
</template>

<style>

.disabled:hover {
  cursor: not-allowed !important;
}
.ql-container{
  height: 70%;
}
</style>
