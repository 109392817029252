<template>
  <!-- Banner Section -->
  <section class="banner-section">
    <div class="banner-circle">
      <img src="../../../assets/img/bannerbg.png" class="img-fluid" alt="bannercircle">
    </div>
    <div class="container">
      <div class="home-banner">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="section-search aos" data-aos="fade-up">
              <p class="explore-text">
                <span>RFiJ Agency</span>
              </p>
              <img src="../../../assets/img/arrow-banner.png" class="arrow-img" alt="arrow">
              <h1>Votre Partenaire <br>
                <span>Marketing </span> & <span>Field Force </span> Solutions
              </h1>
              <p> RFIJ Agencyest né Suite a une grand expériencedans le milieude lagestion des
                ressourcesHumainesetdu marketingopérationnel.</p>
              <div class="search-box">
                <b-form action="" class="d-flex">
                  <div class="search-input line">
                    <div class="form-group mb-0">
                      <vSelect
                          class="select-home"
                          :options="storesNames"
                          v-model="store"
                          label="name"
                          :reduce="item => item.name"
                          append-to-body
                          filterable
                          placeholder="Choisir Magasin"/>
                    </div>
                  </div>
                  <div class="search-input">
                    <div class="form-group mb-0">

                        <vSelect
                            class="select-home"
                            v-model="category"
                            :options="categories"
                            label="title"
                            :reduce="item => item.title"
                            append-to-body
                            filterable
                            placeholder="Choisir Categorie"/>
                      </div>
                  </div>
                  <div class="search-btn">
                    <b-button variant="primary" @click="searchJob"><i class="fa fa-search" aria-hidden="true"></i> Recherche</b-button>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="banner-imgs">
              <img src="@/assets/img/home1.png" class="img-fluid" alt="bannerimage">
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/img/bannerellipse.png" class="img-fluid banner-elipse" alt="arrow">
  </section>
  <!-- /Banner Section -->


</template>

<script setup>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import {ref, computed } from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {message} from "ant-design-vue";

const myStore = useStore()

const storesNames = computed(() => myStore.getters["stores/data"])
const categories = computed(() => myStore.getters["categories/data"])
const category = ref(null)
const store = ref(null)
const router = useRouter()

const searchJob = () => {
  let data = {
    store: store.value,
    category: category.value
  }
  if (store.value || category.value){
    router.push({
      name: "jobs",
      params: { q: JSON.stringify(data) }
    });
  }else{
    message.info('Merci de Choisir Magasin ou Bien une Categorie')
  }
}

</script>

<style>
.select-home {
  width: 300px;
  background: #fff;
  height: 50px;
  border-radius: 5px;
}
.select-home input {
  height: 40px !important;
}
</style>