<template>
  <!-- Cowork Section -->
  <section class="cowork-section">
    <div class="container">
      <div class="cowork-sec">
        <div class="row">
          <div class="col-md-9">
            <div class="section-heading heading-four aos" data-aos="fade-up">
              <h2>Découvrez Nos Expertises</h2>
              <p>RFiJ vous Offrir des services intégrés de gestion du personnel à haut niveau, de marketing
                créatif et de production d’événements</p>
            </div>
          </div>
          <div class="col-md-3 text-md-end aos" data-aos="fade-up">
<!--            <div class="interset-btn">-->
<!--              <router-link to="/service-details" class="btn btn-primary more-btn">Voir Plus <i-->
<!--                  class="feather-arrow-right"></i></router-link>-->
<!--            </div>-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 aos" data-aos="fade-up" v-for="item in services" :key="item.id">
            <div class="cowork-box">
              <router-link :to="item.route">
                <div class="cowork-img">
                  <img :src="require(`@/assets/img/${item.image}`)" class="img-fluid" alt="img" style="max-width: 70%;
">
                </div>
              </router-link>
              <div class="cowork-info">
                <h5>
                  <router-link :to="item.route">{{ item.title }}</router-link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Cowork Section -->

  <!-- Category Section -->
  <section class="city-section">
    <div class="container">
      <div class="city-sec">
        <div class="row">
          <div class="col-md-9">
            <div class="section-heading heading-four aos" data-aos="fade-up">
              <h2>Qu'est-ce qui vous intéresse</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-2-5 col-md-2 aos" data-aos="fade-up" v-for="item in categories" :key="item.id">
            <div class="city-wrap">
              <div class="city-img">
                <img :src="require(`@/assets/api/categories/${item.image}`)" class="img-fluid" alt="blog-img">
                <a href="#" @click.prevent="searchJob(item.title)">
                  <i class="feather-arrow-right"></i>
                </a>
              </div>
              <div class="city-content">
                <h5>
                  <a href="#" @click.prevent="searchJob(item.title)">{{ item.title }}</a>
                </h5>
                <p><span>{{item.jobs_count}}</span> Offres d'emploi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- /Category Section -->
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const myStore = useStore()
const router = useRouter()
const categories = computed(() => myStore.getters["categories/data"])
const services = computed(() => myStore.getters["services/data"])

const searchJob = (category) => {
  let data = {
    store: null,
    category: category
  }
  if ( category){
    router.push({
      name: "jobs",
      params: { q: JSON.stringify(data) }
    });
  }
}
</script>
<style>
.extra-col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-2-5 {
  @extend .extra-col;
  flex: 0 0 18.833333%;
  max-width: 18.833333%;
}
@media only screen and (max-width: 600px){
  .col-2-5 {
    @extend .extra-col;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

</style>