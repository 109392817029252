<template>

  <section class="contactusform-section">
    <div class="container">

      <div class="row align-items-center mt-4">
        <div class="col-lg-5 col-md-5">
          <div class="contactform-img">
            <img src="@/assets/img/contactform-img.svg" class="img-fluid" alt="">
          </div>
        </div>
        <div class="col-lg-7 col-md-7">

          <div class="contactus-form">
            <b-form class="">
              <div class="form-group">
                <b-form-input type="text" v-model="form.name" class="form-control" placeholder="Nom*" required/>
                <has-error field="name" :form="form" />
              </div>
              <div class="form-group me-0">
                <b-form-input type="email" v-model="form.email" class="form-control" placeholder="Email*" required/>
                <has-error field="email" :form="form" />
              </div>
              <div class="form-group">
                <b-form-input type="text" v-model="form.object" class="form-control" placeholder="Objet"/>
                <has-error field="object" :form="form" />
              </div>
              <div class="form-group">
                <textarea rows="4" v-model="form.content" class="form-control" placeholder="Écrire un message*" required></textarea>
                <has-error field="content" :form="form" />
              </div>
              <div class="submit-section">
                <b-button variant="primary submit-btn" @click="sendEmail"> Envoyer</b-button>
              </div>
            </b-form>

          </div>
        </div>
      </div>
    </div>
  </section>
  <!--contact Information-->
  <div class="contactus-info">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 contactus-img col-md-12">
          <div class="contactleft-info">
            <img src="@/assets/img/contactleftimg.jpg" class="img-fluid" alt="">
            <div class="contactinfo-content">
              <div class="contact-hours">
                <h6>Contacter Nous</h6>
                <ul>
                  <li><i class="fas fa-location-dot"></i> {{company?.address}}</li>
                  <li><i class="fas fa-envelope"></i> {{company?.email}}</li>
                  <li><i class="fas fa-phone"></i> {{company?.phone}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 contactright-map col-md-12">
          <div class="google-maps">


            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.7774608411964!2d-7.57991102377596!3d33.585126842250126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7cd87adec85c7%3A0x7f2991d9f3466db7!2sRFiJ%20Agency!5e0!3m2!1sen!2sus!4v1689723548798!5m2!1sen!2sus"
                width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!--/contact Information-->

</template>

<script setup>
import {HasError} from "vform/src/components/bootstrap5";
import {computed, reactive} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {useStore} from "vuex";

import Form from "vform";
Form.axios = axiosIns

const company = computed(() => store.getters["config/getCompaty"] )
const store = useStore()

const form = reactive(new Form({
  name: null,
  email: null,
  object: null,
  content: null
}))

const sendEmail = () => {
  form.post('send-email')
}


</script>