<template>
  <div class="page-wrapper">
    <a-spin :spinning="loading">
      <section class="details-description" style="padding-top: 140px">
        <div class="container">
          <div class="about-details">
            <div class="about-headings">
              <div class="authordetails">
                <h2>{{ job?.title }}</h2>
              </div>
            </div>
          </div>
          <div class="descriptionlinks">
            <div class="row">
              <div class="col-lg-8">
                <ul>
                  <li><a href="javascript:void(0);"><i class="feather-map"></i> {{ job.city }}</a></li>
                  <li><a href="javascript:void(0);"><i class="feather-map-pin"></i> {{ job.store }}</a></li>
                  <li><a href="javascript:void(0);"><i class="feather-bookmark"></i> {{ job.category }}</a></li>
                  <li><a href="javascript:void(0);"><i class="feather-heart"></i> Enregister</a></li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-6 d-flex justify-content-between mb-3">
                <div class="callnow">
                  <router-link style="padding: 14px 20px;" to="/jobs/list"><i class="feather-arrow-left"></i> Retour à la liste</router-link>
                </div>
                <div class="callnow">
                  <a-popconfirm title="Confirmer ?" @confirm="postuler(job)">
                    <a href="#" style="padding: 14px 20px;"><i class="feather-send"></i> Postuler</a>
                  </a-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="details-main-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="card ">
                <div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span>
                  <h4>Description</h4>
                </div>
                <div class="card-body">
                  <div v-html="job.description"></div>
                </div>
              </div>

              <!--Listing Features Section-->
              <div class="card ">
                <div class="card-header">
                  <i class="feather-list"></i>
                  <h4>Missions</h4>
                </div>
                <div class="card-body">
                  <div class="lisiting-featues">
                    <div id="missions" class="row" v-html="job.missions"></div>
                  </div>
                </div>
              </div>
              <!--/Listing Features Section-->

              <!--/Review Section-->

            </div>
            <div class="col-lg-3 theiaStickySidebar">
              <div class="stickysidebar">
                <div class="rightsidebar">
                  <div class="card">
                    <h4><img src="@/assets/img/details-icon.svg" alt="location"> Extra</h4>
                    <ul class="statistics-list">
                      <li>
                        <div class="statistic-details"><span class="icons"><i class="feather-check"></i></span>
                          Flexibilité
                        </div>
                      </li>
                      <li>
                        <div class="statistic-details"><span class="icons"><i class="feather-check"></i></span>
                          Conventionnel
                        </div>
                      </li>
                      <li>
                        <div class="statistic-details"><span class="icons"><i class="feather-check"></i></span>
                          Extraversion
                        </div>
                      </li>
                      <li class="mb-0">
                        <div class="statistic-details"><span class="icons"><i class="feather-check"></i></span>
                          Distance émotionnelle
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

  </div>
</template>

<script setup>

import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {isUserLoggedIn} from "@/router/utils";
import {message} from "ant-design-vue";

const route = useRoute()

const job = ref({})
const loading = ref(false)

const getJob = async (id) => {
  loading.value = true
  const res = await axiosIns.get(`get-job/${id}`)
  job.value = res.data
  loading.value = false
}

const postuler =  (item) => {
  const isLogged = isUserLoggedIn()
  if (!isLogged) {
    return message.info('Vous devez Conneter pour Postuler')
  }

  axiosIns.post('postule', {id: item.id})
}
onMounted(() => getJob(route.params.id))
</script>

<style >
#missions ul {
  list-style: none;
}
#missions ul li::before {
  content: "\e92b";
  font-family: 'feather' !important;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  top: 0.1rem;
  margin-right: 0.2rem;
}

#missions ul li {
  margin-bottom: 0.2rem;
}
</style>