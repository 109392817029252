<template>
    <div class="mainwrapper error-page">
	   	<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 mx-auto">
						<div class="error-wrap">
							<h2>Page Introuvable !!</h2>
							<div class="error-img">
								<img class="img-fluid" src="@/assets/img/401-error.png" alt="img" style="max-width: 70%">
							</div>
							<router-link to="/" class="btn btn-primary rounded-pill">Retour à la page d'accueil</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>