<template>
<div>
  <div class="card-header">
    <div class="d-flex justify-content-between" style="width: 180px">
      <h4>Liste Categories</h4>
      <div v-if="total" class="badge badge-success rounded-pill mt-0 ">{{ total }}</div>
    </div>
    <div class="d-flex justify-content-between">
      <a class="nav-link header-login add-listing bg-danger" style="margin-right: 10px" @click="clearKeyword"><i
          class="fa-solid fa-filter"></i>
        Supprimer Filter</a>
      <a class="nav-link header-login add-listing" style="margin-right: 10px" @click="getData"><i
          class="fa-solid fa-sync"></i>
        Actualiser</a>
      <a class="nav-link header-login add-listing bg-success" @click="OpenModel(null)"><i
          class="fa-solid fa-plus"></i>
        Ajouter</a>
    </div>
  </div>
  <div class="card-body">
     <div class="table-responsive">
       <a-table
           class="stripped table-hover listing-table datatable"
           :columns="columns"
           :loading="loading"
           :data-source="tableData"
           :pagination="false"
           :row-key="record => record.id"
           @change="handleTableChange"
       >
         <template #bodyCell="{ column, record }">
           <template v-if="column.key === 'action'">
             <div class="action">
               <a href="javascript:void(0)" class="action-btn bg-primary text-light" title="Modifier"
                  @click="OpenModel(record)"> <i
                   class="fas fa-edit"></i></a>
               <a-popconfirm title="Supprimer ?" @confirm="deleteItem(record)">
                 <a href="javascript:void(0)" class="action-btn bg-danger text-light" title="Supprimer"> <i
                     class="fas fa-trash"></i></a>
               </a-popconfirm>
             </div>
           </template>

           <template v-else>
             <span>{{ record[column.key] }}</span>
           </template>
         </template>
       </a-table>
     </div>
     <div class="text-center">
       <a-pagination
           v-model:current="params.page"
           v-model:page-size="params.limit"
           :total="total"
           showQuickJumper
           :disabled="loading"
           :show-total="total => `Total ${total} Magasin`"
           @change="goToPage"
       />
     </div>
   </div>
  <a-modal
      v-model:visible="visible"
      title="Categories"
      width="500px"
      centered
      ok-text="Enregister"
      cancel-text="Férmer"
      @ok="submitData"
  >
    <a-form layout="vertical">
      <a-row :gutter="12">
        <a-col :span="12">
          <a-form-item name="title" label="Titre" :validateStatus="form.errors.has('title') ? 'error' : ''"
                       :help="form.errors.get('title')">
            <a-input v-model:value="form.title" @input="form.errors.clear('title')"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item name="title" label="Image" :validateStatus="form.errors.has('image') ? 'error' : ''"
                       :help="form.errors.get('image')">
            <a-upload
                accept="image/*"
                :before-upload="beforeUploadCv"
                :max-count="1"
                class="img-up"
            >
              <a-button  class="w-100">
                <upload-outlined></upload-outlined>
                Charger une image
              </a-button>
              <template #itemRender/>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <div v-if="imageUrl" class="text-center">
            <img :src="imageUrl" :alt="imageFileName" style=" width: 100%; height: 320px; margin-bottom: 10px">
            <a-button danger @click="removeImage">Supprimer</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</div>
</template>

<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import Form from "vform";
Form.axios = axiosIns

const columns = ref([
  {
    title: "Titre",
    key: "title",
    sorter: true
  },
  {
    title: "Image",
    key: "image",
  },
  {
    title: "Date création",
    key: "created_at",
    sorter: true
  },

  {
    title: "Action",
    key: "action",
  },
])
const lastPage = ref()
const tableData = ref()
const total = ref(0)
const loading = ref(false)
const fileList = ref([]);
const imageFileName = computed(() => form.image?.name)

let params = reactive({
  page: 1,
  limit: 10,
  columnKey: null,
  order: null,
  keyword: null,
})

const visible = ref(false)
const imageUrl = ref(null)
let form = reactive(new Form({
  id: null,
  title: null,
  image: null,
}))

const handleTableChange = (pag, filters, sorter) => {
  if (sorter.column) {
    params.order = sorter.order
    params.columnKey = sorter.columnKey
  } else {
    params.order = null
  }
  getData()
};

const getData = async () => {
  loading.value = true
  const res = await axiosIns.get('categories', {
    params
  })

  tableData.value = res.data.data
  total.value = res.data.total
  lastPage.value = res.data.last_page;
  params.page = res.data.current_page
  params.limit = res.data.per_page

  loading.value = false
}

const OpenModel = (item) => {
  visible.value = true
  form.reset()
  form.clear()
  removeImage()
  if (item) {
    form.id = item.id
    form.title = item.title
    imageUrl.value = require(`@/assets/api/categories/${item.image}`)
  }
}

const submitData = () => {
  if (form.id) {
    form.post(`u_cat/${form.id}`).then((res) => {
      if (res.data.msg) {
        visible.value = false
        getData()
      }
    })
  } else {
    form.post('categories').then((res) => {
      if (res.data.msg) {
        visible.value = false
        getData()
      }
    })
  }
}

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= lastPage.value) {
    params.page = pageNumber;
    getData()
  }
}

const deleteItem = (item) => {
  form.delete(`categories/${item.id}`).then((res) => {
    if (res.data.msg) {
      getData()
    }
  })
}

const clearKeyword = () => {
  const keys = Object.getOwnPropertyNames(params)
  keys.forEach(item => {
    params[item] = null
  })
  params.limit = 10
  params.page = 1
  getData()
}

const beforeUploadCv = async file => {
  const reader = new FileReader()

  reader.onload = (event) => {
    imageUrl.value = event.target.result
  }

  reader.readAsDataURL(file)
  form.image = file
  return false
};

const removeImage = () => {
  imageUrl.value = null
  form.image = null
  fileList.value = [];
}

onMounted(() => {
  getData()
})
</script>

<style >
.img-up .ant-upload {
  width: 100% !important;
}
</style>