const radarChart = {
    series: [{
        name: 'Offres Postulées',
        data: [1, 2, 7, 3, 8, 12, 19, 27, 4, 9, 17, 20],
    } ,
        {
            name: 'Offres Approvée',
            data: [0, 1, 2, 0, 2, 5, 7, 10, 0, 7, 7, 3],
        }],

    chart: {
        id: "vuechart-example",
        height: 350,
        type: 'bar',
        dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
        },
        colors: ['#666666', '#00aee4', '#666666'],
        dataLabels: {
            enabled: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        toolbar: {
            show: false
        },
        legend: {
            show: false,
        },
        stroke: {
            width: 2
        },
        fill: {
            opacity: 0.1
        },
        markers: {
            size: 0
        },
        xaxis: {
            categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        }
    },

};

export {
    radarChart
}