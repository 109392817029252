<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {useStore} from "vuex";
import Form from "vform";
Form.axios = axiosIns

const store = useStore()
const columns = ref([
  {
    title: "Titre",
    key: "title",
  },
  {
    title: "Employer",
    key: "employer",
  },
  {
    title: "Categorie",
    key: "category",
  },
  {
    title: "Ville",
    key: "city",
  },
  {
    title: "Magasin",
    key: "store",
  },
  {
    title: "Approved",
    key: "approved",
  },
  {
    title: "Date",
    key: "created_at",
  },
  {
    title: "Action",
    key: "action",
  },
])
const lastPage = ref()
const tableData = ref()
const total = ref(null)
const loading = ref(false)
const storesNames = computed(() => store.getters["stores/data"])

const jobStates = ref([
  {label: 'Non Approuvé', value: 0},
  {label: 'Approuvé', value: 1},
])

let form = reactive(new Form({
  id: null,
  approved: null,
}))


let params = reactive({
  page: 1,
  limit: 10,
  columnKey: null,
  order: null,
  keyword: null,
})

const categories = ref()
const cities = ref()

const handleTableChange = (pag, filters, sorter) => {

  if (sorter.column) {
    params.order = sorter.order
    params.columnKey = sorter.columnKey
  } else {
    params.order = null
  }
  getData()
};

const getData = async () => {
  loading.value = true
  const res = await axiosIns.get('applicant_jobs', {
    params
  })

  tableData.value = res.data.data
  total.value = res.data.total
  lastPage.value = res.data.last_page;
  params.page = res.data.current_page
  loading.value = false
}

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= lastPage.value) {
    params.page = pageNumber;
    getData()
  }
}

const deleteItem = (item) => {
  form.delete(`applicant_jobs/${item.id}`).then(res => {
    if (res.data.msg){
      getData()
    }
  })
}

const getCities = async () => {
  const res = await axiosIns.get('model/categories')
  categories.value = res.data
}
const getCategories = async () => {
  const res = await axiosIns.get('model/cities')
  cities.value = res.data
}

const clearKeyword = () => {
  const keys = Object.getOwnPropertyNames(params)
  keys.forEach(item => {
      params[item] = null
  })
  params.limit = 10
  params.page = 1
  getData()
}

const resolveState = (state) => {
  if(state === 1){
    return 'Approuvé'
  }else{
    return 'Non Approuvé'
  }
}


onMounted(() => {
  getData()
  getCities()
  getCategories()
  if (store.getters["stores/data"].length === 0) {
    store.dispatch("stores/getData")
  }

})
</script>

<template>
  <div class="dash-cards card">
    <div class="card-header">

      <div class="d-flex justify-content-between" style="width: 240px">
        <h4>Liste Offres d'emploi</h4>
        <div v-if="total" class="badge badge-success rounded-pill mt-0 ">{{ total }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <a class="nav-link header-login add-listing bg-danger" style="margin-right: 10px" @click="clearKeyword"><i
            class="fa-solid fa-filter"></i>
          Supprimer Filter</a>
        <a class="nav-link header-login add-listing" style="margin-right: 10px" @click="getData"><i
            class="fa-solid fa-sync"></i>
          Actualiser</a>
      </div>
    </div>
    <div class="card-body">
      <div class="listing-search">
        <div class="filter-content form-group">
          <a-input-search
              v-model:value="params.keyword"
              placeholder="Recharche par Titre"
              allow-clear
              size="large"
              :loading="loading"
              @search="getData"
          />
        </div>
        <div class="sorting-div">
          <div class="sortbyset">
            <a-select
                style="width: 150px; margin-right: 10px"
                placeholder="Choisir Statut"
                v-model:value="params.approved"
                :options="jobStates"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>

            <a-select
                style="width: 300px; margin-right: 10px"
                placeholder="Choisir Magasin"
                v-model:value="params.store"
                :options="storesNames?.map(item => ({ value: item.name }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
            <a-select
                style="width: 200px; margin-right: 10px"
                placeholder="Choisir Categorie"
                v-model:value="params.category"
                :options="categories?.map(item => ({ value: item.title }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
            <a-select
                style="width: 200px; margin-right: 10px"
                v-model:value="params.city"
                placeholder="Choisir Ville"
                :options="cities?.map(item => ({ value: item.name }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>

          </div>
        </div>
      </div>
      <div class="table-responsive">
        <a-table
            class="stripped table-hover listing-table datatable"
            :columns="columns"
            :loading="loading"
            :data-source="tableData"
            :pagination="false"
            :row-key="record => record.id"
            @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'approved'">
              <a-tag :color="record.pivot.approved === 0 ? 'red' : 'green' ">{{ resolveState(record.pivot.approved) }}</a-tag>
            </template>
            <template v-else-if="column.key === 'created_at'">
              <span>{{ record.pivot.created_at }}</span>
            </template>
            <template v-else-if="column.key === 'action'">
              <div class="action">
                <a-popconfirm title="Supprimer ?" @confirm="deleteItem(record.pivot)" >
                  <a href="javascript:void(0)" class="action-btn bg-danger text-light" title="Supprimer"> <i
                      class="fas fa-trash"></i></a>
                </a-popconfirm>
              </div>
            </template>
            <template v-else>
              <span>{{ record[column.key] }}</span>
            </template>

          </template>
        </a-table>
      </div>
      <div class="text-center">
        <a-pagination
            v-model:current="params.page"
            v-model:page-size="params.limit"
            :total="total"
            showQuickJumper
            :show-total="total => `Total ${total} Offres d'emploi`"
            @change="goToPage"
        />
      </div>
    </div>
  </div>
</template>

<style>

.disabled:hover {
  cursor: not-allowed !important;
}
.ql-container{
  height: 70%;
}
</style>
