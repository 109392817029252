<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {useStore} from "vuex";
import Form from "vform";
import {message} from "ant-design-vue";
Form.axios = axiosIns

const store = useStore()
const columns = ref([
  {
    title: "Nom Complete",
    key: "fullname",
    sorter: true
  },
  {
    title: "Email",
    key: "email",
    sorter: true

  },
  {
    title: "Mobile",
    key: "phone",
    sorter: true

  },
  {
    title: "Magasin",
    key: "store",
    sorter: true

  },
  {
    title: "Ville",
    key: "city",
    sorter: true

  },
  {
    title: "Sexe",
    key: "sexe",
    sorter: true
  },

  {
    title: "Action",
    key: "action",
  },
])
const lastPage = ref()
const tableData = ref()
const total = ref(null)
const loading = ref(false)
const storesNames = computed(() => store.getters["stores/data"])
let params = reactive({
  page: 1,
  limit: 10,
  filters: null,
  columnKey: null,
  order: null,
  keyword: null,
})

const visible = ref(false)
const cities = ref()
let form = reactive(new Form({
  id: null,
  fullname: null,
  email: null,
  phone: null,
  store: null,
  city: null,
  sexe: null,
}))

const handleTableChange = (pag, filters, sorter) => {

  if (sorter.column) {
    params.order = sorter.order
    params.columnKey = sorter.columnKey
  } else {
    params.order = null
  }
  getData()
};

const getData = async () => {
  loading.value = true
  const res = await axiosIns.get('applicants', {
    params
  })

  tableData.value = res.data.data
  total.value = res.data.total
  lastPage.value = res.data.last_page;
  params.page = res.data.current_page
  loading.value = false
}

const OpenModel = (item) => {
  visible.value = true
  form.reset()
  form.clear()
  if (item){
    form.fill(item)
  }
}

const submitData = () => {
  if (form.id) {
    form.put(`applicants/${form.id}`).then((res) => {
      if (res.data.msg){
        visible.value = false
        getData()
      }
    })
  }
  else{
    form.post('applicants')
  }
}

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= lastPage.value) {
    params.page = pageNumber;
    getData()
  }
}

const deleteItem = (item) => {
  form.delete(`applicants/${item.id}`)
  getData()
}

const downloadCv = (filename) => {
  axiosIns({
    url: `/download-file/${filename}`,
    method: 'GET',
    responseType: 'blob', // Important! This tells Axios to treat the response as a binary blob
  })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        message.error('Error downloading file');
      });
}

const getCities = async () => {
  const res = await axiosIns.get('model/cities')
  cities.value = res.data
}

const clearKeyword = () => {
  const keys = Object.getOwnPropertyNames(params)
  keys.forEach(item => {
    params[item] = null
  })
  params.limit = 10
  params.page = 1
  getData()
}

onMounted(() => {
  getData()
  getCities()
  if (store.getters["stores/data"].length === 0) {
    store.dispatch("stores/getData")
  }
})
</script>

<template>
  <div class="dash-cards card">
    <div class="card-header">

      <div class="d-flex justify-content-between" style="width: 180px">
        <h4>Liste Condidates</h4>
        <div v-if="total" class="badge badge-success rounded-pill mt-0 ">{{ total }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <a class="nav-link header-login add-listing bg-danger" style="margin-right: 10px" @click="clearKeyword"><i
            class="fa-solid fa-filter"></i>
          Supprimer Filter</a>
        <a class="nav-link header-login add-listing bg-info" style="margin-right: 10px"><i
            class="fa-solid fa-download"></i>
          Export</a>
        <a class="nav-link header-login add-listing" style="margin-right: 10px" @click="getData"><i
            class="fa-solid fa-sync"></i>
          Actualiser</a>
      </div>
    </div>
    <div class="card-body">
      <div class="listing-search">
        <div class="filter-content form-group">
          <a-input-search
              v-model:value="params.keyword"
              placeholder="Recharche par Titre"
              allow-clear
              size="large"
              :loading="loading"
              @search="getData"
          />
        </div>
        <div class="sorting-div">
          <div class="sortbyset">
            <a-select
                style="width: 300px; margin-right: 10px"
                placeholder="Choisir Magasin"
                v-model:value="params.store"
                :options="storesNames?.map(item => ({ value: item.name }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>
            <a-select
                style="width: 200px; margin-right: 10px"
                v-model:value="params.city"
                placeholder="Choisir Ville"
                :options="cities?.map(item => ({ value: item.name }))"
                size="large"
                allow-clear
                showSearch
                @change="getData"
            ></a-select>

          </div>
        </div>
      </div>
      <div class="table-responsive">
        <a-table
            class="stripped table-hover listing-table datatable"
            :columns="columns"
            :loading="loading"
            :data-source="tableData"
            :pagination="false"
            :row-key="record => record.id"
            @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key !== 'action'">
              <span>{{ record[column.key] }}</span>
            </template>
            <template v-else>
              <div class="action">
                <a href="javascript:void(0)" class="action-btn bg-primary text-light" title="Modifier" @click="OpenModel(record)"> <i
                    class="fas fa-edit"></i></a>

                <a-popconfirm title="Télécharger CV ?" @confirm="downloadCv(record.cv)" >
                  <a href="javascript:void(0)" class="action-btn bg-success text-light" title="Télécharger CV"> <i
                      class="fas fa-download"></i></a>
                </a-popconfirm>
                <a-popconfirm title="Supprimer ?" @confirm="deleteItem(record)" >
                  <a href="javascript:void(0)" class="action-btn bg-danger text-light" title="Supprimer"> <i
                      class="fas fa-trash"></i></a>
                </a-popconfirm>
              </div>
            </template>
          </template>
        </a-table>
      </div>
      <div class="text-center">
        <a-pagination
            v-model:current="params.page"
            v-model:page-size="params.limit"
            :total="total"
            showQuickJumper
            :show-total="total => `Total: ${total} Candidates`"
            @change="goToPage"
        />
      </div>
    </div>
    <a-modal
        v-model:visible="visible"
        title="Candidat"
        width="900px"
        centered
        ok-text="Enregister"
        cancel-text="Férmer"
        @ok="submitData"
    >
      <a-form layout="vertical">
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Nom Complete" :validateStatus="form.errors.has('fullname') ? 'error' : ''"
                         :help="form.errors.get('fullname')">
              <a-input v-model:value="form.fullname" @input="form.errors.clear('fullname')"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Email" :validateStatus="form.errors.has('email') ? 'error' : ''"
                         :help="form.errors.get('email')">
              <a-input v-model:value="form.email" @input="form.errors.clear('email')"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Mobile" :validateStatus="form.errors.has('phone') ? 'error' : ''"
                         :help="form.errors.get('phone')">
              <a-input v-model:value="form.phone" @input="form.errors.clear('phone')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="8">
            <a-form-item name="title" label="Magasin" :validateStatus="form.errors.has('store') ? 'error' : ''"
                         :help="form.errors.get('store')">
              <a-select
                  v-model:value="form.store"
                  :options="storesNames"
                  :field-names="{ label: 'name', value: 'name' }"
                  @input="form.errors.clear('store')"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Ville" :validateStatus="form.errors.has('city') ? 'error' : ''"
                         :help="form.errors.get('city')">
              <a-select
                  v-model:value="form.city"
                  :options="cities"
                  :field-names="{ label: 'name', value: 'name' }"
                  @input="form.errors.clear('city')"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="title" label="Sexe" :validateStatus="form.errors.has('sexe') ? 'error' : ''"
                         :help="form.errors.get('sexe')">
              <a-select
                  v-model:value="form.sexe"
                  :options="[{value: 'Homme', label: 'Homme' },{value: 'Femme', label: 'Femme' }]"
                  @input="form.errors.clear('sexe')"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

    </a-modal>
  </div>
</template>

<style>
.disabled:hover {
  cursor: not-allowed !important;
}
</style>
