<template>
  <div class="page-wrapper">
    <breadcrumb title="Formulaire d'enregistrement" style="padding: 100px 0 90px;"/>

    <div class="p-2 m-4 w-100">
      <div class="border p-4 container">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Nom / Prénom <span class="text-danger">*</span></label>
              <b-form-input type="text" class="form-control" v-model="form.fullname"
                            :state="form.errors.has('fullname') ? false : null"
                            @input="form.clear('fullname')"
              />
              <b-form-invalid-feedback>{{ form.errors.get('fullname') }}</b-form-invalid-feedback>
            </div>
            <div class="form-group">
              <label>Téléphone <span class="text-danger">* il sera étre definie comme votre mot de passe</span></label>
              <b-form-input type="text" class="form-control" v-model="form.phone"
                            @input="form.clear('phone')" :state="form.errors.has('phone') ? false : null"/>
              <b-form-invalid-feedback>{{ form.errors.get('phone') }}</b-form-invalid-feedback>

            </div>
            <div class="form-group">
              <label>Sexe <span class="text-danger">*</span></label>
              <vSelect
                  class="select-control"
                  :options="['Femme', 'Homme']"
                  v-model="form.sexe"
                  append-to-body
                  placeholder="Choisir Magasin"/>

            </div>
            <div class="form-group">
              <label>Ville <span class="text-danger">*</span></label>
              <b-form-input type="text" class="form-control" v-model="form.city" @input="form.clear('city')"
                            :state="form.errors.has('city') ? false : null"/>
              <b-form-invalid-feedback>{{ form.errors.get('city') }}</b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group me-0">
              <label>Email <span class="text-danger">*</span></label>
              <b-form-input type="email" class="form-control" v-model="form.email" @input="form.clear('email')"
                            :state="form.errors.has('email') ? false : null"/>
              <b-form-invalid-feedback>{{ form.errors.get('email') }}</b-form-invalid-feedback>
            </div>
            <div class="form-group">
              <label>Magasin / Ancien Magasin </label>
              <vSelect
                  class="select-control"
                  :options="storesNames"
                  v-model="form.store"
                  label="name"
                  :reduce="item => item.name"
                  append-to-body
                  filterable
                  placeholder="Choisir Magasin"/>
            </div>
            <div class="form-group me-0 d-flex flex-column">
              <label>Curriculum Vitae (CV) <span class="text-danger">*</span></label>
              <a-upload
                  accept=".doc,.docx,.pdf"
                  :before-upload="beforeUploadCv"
                  @remove="handleRemove"
                  :max-count="1"
                  class="cv-upload"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button variant="outline-info">Charger</b-button>
                  </b-input-group-prepend>
                  <b-form-input :value="cvFileName"></b-form-input>
                </b-input-group>
                <template #itemRender/>
              </a-upload>
              <has-error :form="form" field="cv"></has-error>
            </div>
            <div class="form-group me-0 d-flex flex-column">
              <label>Carte Nationale</label>
              <a-upload
                  accept=".doc,.docx,.pdf"
                  :before-upload="beforeUploadAnth"
                  @remove="handleRemove"
                  :max-count="1"
                  class="cv-upload"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button variant="outline-info">Charger</b-button>
                  </b-input-group-prepend>
                  <b-form-input :value="anthFileName"></b-form-input>
                </b-input-group>
                <template #itemRender/>
              </a-upload>
            </div>
          </div>
        </div>

        <div class="submit-section d-flex justify-content-between flex-column">
          <b-button variant="primary submit-btn" @click.prevent="apply"> Envoyer</b-button>
          <div class="mt-2 d-flex justify-content-between">
            <router-link to="/" class="btn btn-success">
              <i class="fas fa-arrow-left"></i>
              Retour
            </router-link>
            <b-button variant="danger submit-btn" @click="clearForm"> Vider La formulaire</b-button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script setup>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {computed, onMounted, reactive, ref} from "vue";
import Form from "vform";
import {useStore} from "vuex";
import axiosIns from "@/plugins/axiosIns";
import {HasError,} from 'vform/src/components/bootstrap5'
import {setData} from "@/plugins/auth";
import {useRouter} from "vue-router";

Form.axios = axiosIns

const form = reactive(new Form({
  // fullname: 'test 1',
  // email: 'test1@demo.com',
  // phone: "060000001",
  // store: 'Belle Image',
  // city: 'Casablanca',
  // sexe: 'Homme',
  // cv: null,
  // anth: null,
  fullname: null,
  email: null,
  phone: null,
  store: null,
  city: null,
  sexe: null,
  cv: null,
  anth: null,
}))
const fileList = ref([]);
const myStore = useStore()
const router = useRouter()

const storesNames = computed(() => myStore.getters["stores/data"])

const cvFileName = computed(() => form.cv?.name)
const anthFileName = computed(() => form.anth?.name)
const apply = async () => {

  const res = await form.post('inscrire', {
    headers: {'Content-Type': 'multipart/form-data'},
  });

  if (res.data.result){
    setData(res.data.result)
    await router.replace('/dashboard')
  }
}

const handleRemove = file => {
  const index = fileList.value.indexOf(file);
  const newFileList = fileList.value.slice();
  newFileList.splice(index, 1);
  fileList.value = newFileList;
};
const beforeUploadCv = async file => {
  form.cv = file
  return false
};

const beforeUploadAnth = async file => {
  form.anth = file
  return false
};

const clearForm = () => {
  form.clear()
  form.reset()
}

onMounted(() => {
  if (myStore.getters["stores/data"].length === 0) {
    myStore.dispatch("stores/getData")
  }
})

</script>

<style>
.select-control {
  width: 100%;
  background: #fff;
  height: 46px;
  border-radius: 5px;
}

.select-control input {
  height: 36px !important;
}

.cv-upload .ant-upload {
  width: 100%;
}

</style>