import Cookies from "js-cookie"
import jwtDefaultConfig from '@/plugins/auth/DefaultJwtConfig'
import ability from "@/plugins/casl/ability";
import {AES, enc} from 'crypto-js';

const keyword = 'jamalsaid'

export const getToken = () => {
    const envrypted = Cookies.get(jwtDefaultConfig.storageTokenKeyName)
    if (envrypted) {
        const decrypted = AES.decrypt(envrypted, keyword);
        return JSON.parse(decrypted.toString(enc.Utf8));
    }
}

export const setToken = value => {
    Cookies.set(jwtDefaultConfig.storageTokenKeyName, AES.encrypt(JSON.stringify(value), keyword), {sameSite: 'strict', path: window.location.href})
}

export const deleteToken = () => {
    Cookies.remove(jwtDefaultConfig.storageTokenKeyName)
}

export const setData = data => {
    const {accessToken, user, userAbilities, candidate, role} = data

    ability.update(userAbilities)

    storeCryptData('user', user)

    storeCryptData('userAbilities', userAbilities)

    storeCryptData('candidate', candidate)

    storeCryptData('role', role)

    if (accessToken) setToken(accessToken)

}

export const removeData = () => {
    deleteToken()
    localStorage.removeItem('user')
    localStorage.removeItem('role')
    localStorage.removeItem('candidate')
    localStorage.removeItem('userAbilities')
}

export const storeCryptData = (key, data) => {
    localStorage.setItem(key, AES.encrypt(JSON.stringify(data), keyword))
}

export const getCryptedData = (key) => {

    const envrypted = localStorage.getItem(key)
    if (envrypted) {
        const decrypted = AES.decrypt(envrypted, keyword);
        return JSON.parse(decrypted.toString(enc.Utf8));
    }
}

