<template>
  <!-- Login Section -->
  <div class="login-content">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <div class="login-wrap password-form">

            <div class="login-header">
              <h3>Mot de passe oublié</h3>
              <p>Entrez votre email et nous vous enverrons un lien pour réinitialiser votre mot de passe.</p>
            </div>

            <!-- Login Form -->
            <b-form action="login">
              <div class="form-group group-img">
                <div class="group-img">
                  <i class="feather-mail"></i>
                  <b-form-input type="text" class="form-control" placeholder="Email"/>
                </div>
              </div>
              <b-button variant="primary w-100 login-btn" type="submit">Envoyer</b-button>
            </b-form>
            <router-link to="/" class="back-home"><i class="fas fa-regular fa-arrow-left me-1"></i>Retour à l'accueil
            </router-link>
            <!-- /Login Form -->

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- /Login Section -->
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>