<template>
  <div class="login-content">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <div class="login-wrap">

            <div class="login-header">
              <h3>Se Connecter</h3>
            </div>

            <!-- Login Form -->
            <b-form action="/dashboard">
              <div class="form-group group-img">
                <div class="group-img">
                  <i class="feather-mail"></i>
                  <b-form-input v-model="form.email" type="email" class="form-control" placeholder="Email"
                                @keydown.enter="login"/>
                </div>
              </div>
              <div class="form-group">
                <div class="pass-group group-img">
                  <span class="lock-icon"><i class="feather-lock"></i></span>
                  <b-form-input
                      v-if="showPassword"
                      type="text"
                      class="form-control pass-input"
                      v-model="form.password"
                      placeholder="Mot de Passe"
                      @keydown.enter="login"
                  />
                  <b-form-input v-else type="password"
                                class="form-control pass-input"
                                placeholder="password" v-model="form.password" @keydown.enter="login"/>
                  <span
                      class="toggle-password"
                      @click="toggleShow"
                      :class="{
											'feather-eye': showPassword,
											'feather-eye-off': !showPassword,
											}"
                  ></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <label class="custom_check">
                    <input type="checkbox" name="rememberme" class="rememberme">
                    <span class="checkmark"></span>Mémoriser l'email
                  </label>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="text-md-end">
<!--                    <router-link class="forgot-link" to="forgot-password">Mot de passe oublié?</router-link>-->
                  </div>
                </div>
              </div>
              <b-button variant="primary w-100 login-btn" @click="login">Connecter</b-button>
              <div class="register-link text-center">
                <p>
                  <router-link class="forgot-link" to="/inscrire">S'inscrire</router-link>
                  <router-link class="forgot-link" to="/">Accueil</router-link>
                </p>
              </div>
            </b-form>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import Form from 'vform'
import {reactive, ref} from "vue";
import axios from "@/plugins/axiosIns";
import {setData} from "@/plugins/auth"
import {useRoute, useRouter} from "vue-router";


const route = useRoute()
const router = useRouter()

const showPassword = ref(false)
const form = reactive(new Form({
  email: '',
  password: ''
}))

const toggleShow = () => {
  showPassword.value = !showPassword.value
}

const login = async () => {
  const res = await axios.post('login', form)
  if (res.data.result) {
    setData(res.data.result)
    if (route.query.to)
      return router.replace(String(route.query.to))
    else return router.replace('/dashboard')
  }
}


</script>
