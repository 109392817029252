<template>
  <div class="">
    <ul class="dashborad-menus">
      <div class="w-100 d-flex mobile-m">
        <template v-for="(item, index) of listItems" :key="index">
          <li v-if="canViewNavMenu(item)" :class="$route.path === item.route ? 'active' : 'inactive'">
            <router-link :to="item.route">
              <i :class="item.icon"></i> <span>{{ item.title }}</span>
            </router-link>
          </li>
        </template>
      </div>
      <div class="nav-item dropdown has-arrow logged-item float-end ">
        <a href="#" class="dropdown-toggle profile-userlink" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="@/assets/img/profile-img.jpg" alt="">
          <span>{{ user?.username }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <router-link class="dropdown-item" to="/profile">Profile</router-link>
          <router-link class="dropdown-item" to="/">Page D'accueil</router-link>
          <a href="#" class="dropdown-item" @click.prevent="logout">Se Déconnecter</a>
        </div>
      </div>
    </ul>
  </div>

</template>

<script setup>
import {computed, ref, watch} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {getCryptedData, removeData, setData} from "@/plugins/auth";
import {useRoute, useRouter} from "vue-router";
import {canViewNavMenu} from "@/plugins/casl/gate";
import menuList from "@/views/pages/Admin/menuList";

const router = useRouter()
const route = useRoute()

watch(route, async () => {
  const res = await axiosIns.get('user-abilities')
  setData(res.data)
})

const user = computed(() => {
  return getCryptedData('user') ?? null
})

const logout = () => {
  axiosIns.post('logout')
      .then((res) => {
        if (res.data.msg) {
          removeData()
          router.push({name: 'Login'})
        }
      })
}

const listItems = ref(menuList)


</script>

<style scoped>
ul.dashborad-menus {
  background: #fff;
  border-radius: 10px;
  padding: 14px 12px;
  align-items: center;
  display: flex;
  box-shadow: 3px 5px 13px rgb(222 226 231 / 44%);
  border-bottom: 1px solid #ededed;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

ul.dashborad-menus li {
  margin-right: 12px;
}

ul.dashborad-menus li:last-child {
  margin-right: 0px;
}

ul.dashborad-menus li a {
  padding: 12px 26px;
  color: #666;
  border-radius: 5px;
}

ul.dashborad-menus li.active a, .dashboard-content ul.dashborad-menus li a:hover {
  background: #00aee4;
  color: #fff;
  border-radius: 5px;
}

ul.dashborad-menus li.inactive a {
  background: #f2f2f2;
}

ul.dashborad-menus li a i {
  margin-right: 5px;
}

</style>
