import {createRouter, createWebHistory} from 'vue-router';
import routes from "@/router/routes";
import {isUserLoggedIn} from "@/router/utils";
import {canNavigate} from "@/plugins/casl/gate";

 const router = createRouter({
    history: createWebHistory(''),
    linkActiveClass: 'active',
     base: process.env.BASE_URL,
     scrollBehavior() {
         return { top: 0 }
     },
    routes,
});

router.beforeEach((to, _, next) => {

    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {

        if (!isLoggedIn) {
            return next({name: 'Login', query: {to: to.name !== 'dashboad' ? to.fullPath : undefined}})
        }

        return next({ name: 'error-401' })
    }

    if(to.meta.redirectIfLoggedIn && isLoggedIn) {
        next('dashboard')
    }

    return next()
})

export default router