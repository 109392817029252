<template>

<div class="page-wrapper">
<div class="blogbanner">

  <div class="blogbanner-content">
    <h1>Production Des Evénements</h1>
    <span class="blog-hint">Expertise</span>

  </div>
</div>
<!--/Blog Banner-->

<!--Blog Content-->
<div class="blogdetail-content">
  <div class="container">
    <div class="card">
      <div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span>
        <h4>Description</h4>
      </div>
      <div class="card-body">
        <p>RFIJ Agency vous offrent les meilleurs endroits pour vous événement et
          conférences à Casablanca comme ailleurs,
        </p>
        <p>Les endroits ou on a travailliez:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <i class="feather-image"></i>
            <h4>Cabestan Ocean View </h4>
          </div>
          <div class="card-body">
            <img src="../../../../assets/img/events/e1.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <i class="feather-image"></i>
            <h4>Skybar</h4>
          </div>
          <div class="card-body">
            <img src="../../../../assets/img/events/e2.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <i class="feather-image"></i>
            <h4>Sofitel Tour Blanche Casablanca</h4>
          </div>
          <div class="card-body">
            <img src="../../../../assets/img/events/e3.png" class="img-fluid" alt="" >
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</div>

</template>
<script setup>


</script>