<template>
  <div class="page-wrapper">
    <breadcrumb :title="title" :name="name" :text="text" :text1="text1"/>

    <contactinformation/>
  </div>

</template>

<script>
export default {
  data() {
    return {
      title: "Contacter-Nous",
      text: "Acceuill",
      text1: "Contacter-Nous",
      name: "/",
    }
  },
}
</script>