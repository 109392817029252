import axiosIns from "@/plugins/axiosIns";

export default {
    namespaced: true,
    state: {
        isLoading: true,
        company: null,
    },
    getters: {
        isLoading: state => state.isLoading,
        getCompaty: state => state.company,
    },
    actions: {
        async getData({commit }) {
            const res = await axiosIns.get('config');
            commit('SET_DATA', res.data);
        },
    },
    mutations: {
        SET_LOADING(state, data) {
            state.isLoading = !state.isLoading;
        },
        SET_DATA(state, data) {
            state.company = data;
        },
    }
}