<template>
  <div >
    <div class="row">
      <div class="col-lg-3 col-md-3 ">
        <div class="card dash-cards">
          <div class="card-body d-flex justify-content-between">
            <div class="dash-top-content">
              <div class="dashcard-img">
                <img :src="require(`@/assets/img/dashboard/jobs.png`)" class="img-fluid"
                     alt="">
              </div>
            </div>
            <div class="dash-widget-info my-auto text-center">
              <h6>Offres D'emploi</h6>
              <h3 v-if="!loading" class="counter">{{ jobs_Count }}</h3>
              <a-spin v-else />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 ">
        <div class="card dash-cards">
          <div class="card-body d-flex justify-content-between">
            <div class="dash-top-content">
              <div class="dashcard-img">
                <img :src="require(`@/assets/img/dashboard/applied_jobs.png`)" class="img-fluid"
                     alt="">
              </div>
            </div>
            <div class="dash-widget-info my-auto text-center">
              <h6>Offres Postulées</h6>
              <h3 v-if="!loading" class="counter">{{ applied_Jobs }}</h3>
              <a-spin v-else />
            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-3 col-md-3 ">
        <div class="card dash-cards">
          <div class="card-body d-flex justify-content-between">
            <div class="dash-top-content">
              <div class="dashcard-img">
                <img :src="require(`@/assets/img/dashboard/candidates.png`)" class="img-fluid"
                     alt="">
              </div>
            </div>
            <div class="dash-widget-info my-auto text-center">
              <h6>Candidates</h6>
              <h3 v-if="!loading" class="counter">{{ applicants }}</h3>
              <a-spin v-else />

            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-3 col-md-3 ">
        <div class="card dash-cards">
          <div class="card-body d-flex justify-content-between">
            <div class="dash-top-content">
              <div class="dashcard-img">
                <img :src="require(`@/assets/img/dashboard/users.png`)" class="img-fluid"
                     alt="">
              </div>
            </div>
            <div class="dash-widget-info my-auto text-center">
              <h6>Utilisateurs</h6>
              <h3 v-if="!loading" class="counter">{{ users }}</h3>
              <a-spin v-else />

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row dashboard-info mt-3">
      <div class="col-lg-6 d-flex">
        <div class="card dash-cards w-100">
          <div class="card-header">
            <h4>Statistic Des Offres</h4>
            <div class="card-dropdown">
              <ul>
                <li class="nav-item dropdown has-arrow logged-item">
                  <router-link to="#" class="dropdown-toggle pageviews-link"
                               data-bs-toggle="dropdown" aria-expanded="false">
                    <span>Année</span>
                  </router-link>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="javascript:void();">2023</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <apexchart type="bar" height="350"
                       :options="radarChart.chart"
                       :series="radarChart.series">
            </apexchart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex">
        <div class="card dash-cards w-100">
          <div class="card-header">
            <h4>Dernières 3 offres d'emploi publiées</h4>
            <div class="card-dropdown">
              <a href="#" class="search-btn" @click.prevent="getDashboard"> <span><i class="feather-refresh-cw"></i></span></a>
            </div>
          </div>
          <div class="card-body">
            <ul class="review-list mb-2" v-for="item in jobs" :key="item.id">
              <li class="review-box">
                <div class="review-profile">
                  <div class="review-img">
                    <img :src="require(`@/assets/api/jobs/${item.image}`)"
                         class="img-fluid" alt="img">
                  </div>
                </div>
                <div class="review-details">
                  <h6>{{ item.title }}</h6>
                  <div class="rating">
                    <div><i class="fa-sharp fa-solid fa-calendar-days"></i>{{ item.created_at }}</div>
                    <div><i class="fa-sharp fa-solid fa-bookmark"></i>{{ item.category }}</div>
                    <div><i class="fa-sharp fa-solid fa-shop"></i>{{ item.store }}</div>
                  </div>
                  <div class="truncate-overflow" v-html="item.description"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from '../../../../assets/json/dashboard.json'
import DashboardReview from '../../../../assets/json/dashboardReview.json'
import {
  radarChart
}
  from "./data";
export default {
  data() {
    return {
      Dashboard: Dashboard,
      DashboardReview: DashboardReview,
      radarChart: radarChart
    }
  },
}
</script>

<script setup>
import axiosIns from "@/plugins/axiosIns";
import {onMounted, ref} from "vue";

const loading = ref(true)
const jobs_Count = ref(0)
const applied_Jobs = ref(0)
const applicants = ref(0)
const users = ref(0)

const jobs = ref([])

const getDashboard = () => {
  axiosIns.get('dashboard').then(res => {

    const {jobCount, appliedJobs, applicantsCount, usersCount, latestJobs} = res.data

    jobs_Count.value = jobCount
    applied_Jobs.value = appliedJobs
    applicants.value = applicantsCount
    users.value = usersCount
    jobs.value = latestJobs

    loading.value = false
  })

}

onMounted(() => {
  getDashboard()
})
</script>