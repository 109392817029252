import { Ability } from '@casl/ability'
import {getCryptedData} from "@/plugins/auth";

export const initialAbility = []

//  Read ability from localStorage
// 👉 Handles auto fetching previous abilities if already logged in user
// ℹ️ You can update this if you store user abilities to more secure place
// ❗ Anyone can update localStorage so be careful and please update this
const stringifiedUserAbilities = getCryptedData('userAbilities')
const existingAbility = stringifiedUserAbilities ? stringifiedUserAbilities : null
export default new Ability(existingAbility || initialAbility)