<template>
  <div class="profile-content">
    <div class="row dashboard-info">
      <div class="col-lg-9">
        <div class="card dash-cards">
          <div class="card-header">
            <h4>Détails du profil</h4>
          </div>
          <div class="card-body">
            <div class="profile-photo">
              <div class="profile-img">
                <div class="settings-upload-img">
                  <img :src="imageUrl" alt="profile">
                </div>
                <div class="settings-upload-btn">
                  <input type="file" accept="image/*" name="image" class="hide-input image-upload"
                         @change="onFileSelected">
                  <label for="file" class="file-upload">Télécharger une nouvelle photo</label>
                </div>
              </div>
              <b-button variant="primary" @click="submit">Enregistrer</b-button>

            </div>
            <div class="profile-form">
              <b-form>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Nom Complete</label>
                      <div class="pass-group group-img">
                        <span class="lock-icon"><i class="feather-user"></i></span>
                        <b-form-input type="text" v-model="form.fullname" class="form-control ml-2" placeholder=""/>
                      </div>
                      <HasError :form="form" field="fullname" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Ville</label>
                      <div class="group-img">
                        <i class="feather-mail"></i>
                        <b-form-select class="form-control" v-model="form.city" :options="cities.map(i => ({value: i.name, text: i.name}))" style="padding: 13px 13px 13px 40px"></b-form-select>
                      </div>
                      <HasError :form="form" field="city" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Numéro de téléphone</label>
                      <div class="pass-group group-img">
                        <span class="lock-icon"><i class="feather-phone-call"></i></span>
                        <b-form-input type="tel" v-model="form.phone" class="form-control"
                                      placeholder=""/>
                      </div>
                      <HasError :form="form" field="phone" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Adresse E-mail</label>
                      <div class="group-img">
                        <i class="feather-mail"></i>
                        <b-form-input type="text" v-model="form.email" class="form-control"
                                      placeholder=""/>
                      </div>
                      <HasError :form="form" field="email" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Curriculum Vitae (CV)
                        <i v-if="form.cv" class="feather-download text-success cursor-clickable" title="Télécharger" @click="downloadFile(form.cv)"></i>
                        <i v-else class="feather-alert-triangle text-danger cursor-clickable" title="Aucun Fichier"></i>
                      </label>
                      <input type="file" class="form-control" accept=".doc, .docx,.pdf" name="image"
                             @change="event => form.cv = event.target.files[0]" style="padding: 14px 25px;">
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Carte Nationale
                        <i v-if="form.anth" class="feather-download text-success cursor-clickable" title="Télécharger" @click="downloadFile(form.anth)"></i>
                        <i v-else class="feather-alert-triangle text-danger" title="Aucun Fichier"></i>
                      </label>
                      <input type="file" class="form-control" accept=".doc, .docx,.pdf" name="image"
                             @change="event => form.anth = event.target.files[0]" style="padding: 14px 25px;">
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="profile-sidebar">
          <div class="card">
            <div class="card-header">
              <h4>Changer le mot de passe</h4>
            </div>
            <div class="card-body">
              <b-form>
                <div class="form-group">
                  <label class="col-form-label">Mot de passe actuel</label>
                  <div class="pass-group group-img">
                    <span class="lock-icon"><i class="feather-lock"></i></span>
                    <b-form-input type="password" v-model="form2.old_password" class="form-control pass-input w-100" placeholder="Password"/>
                    <HasError :form="form2" field="old_password" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Nouveau mot de passe</label>
                  <div class="pass-group group-img">
                    <span class="lock-icon"><i class="feather-lock"></i></span>
                    <b-form-input
                        v-if="showPassword"
                        type="text"
                        class="form-control pass-input w-100"
                        v-model="form2.password"
                        placeholder=".............."
                    />
                    <b-form-input v-else type="password"
                                  class="form-control pass-input w-100"
                                  placeholder=".............." v-model="form2.password"/>
                    <span
                        class="toggle-password"
                        @click="showPassword = !showPassword"
                        :class="{
														'feather-eye': showPassword,
														'feather-eye-off': !showPassword,
														}"
                    ></span>
                  </div>
                  <HasError :form="form2" field="password" />
                </div>
                <div class="form-group">
                  <label class="col-form-label">Confirmer le nouveau mot de passe</label>
                  <div class="pass-group group-img">
                    <span class="lock-icon"><i class="feather-lock"></i></span>
                    <b-form-input
                        v-if="showPassword2"
                        type="text"
                        class="form-control pass-input w-100"
                        v-model="form2.password_confirmation"
                        placeholder=".............."
                    />
                    <b-form-input v-else type="password"
                                  class="form-control pass-input w-100"
                                  placeholder=".............." v-model="form2.password_confirmation"/>
                    <span
                        class="toggle-password"
                        @click="showPassword2 = !showPassword2"
                        :class="{
														'feather-eye': showPassword2,
														'feather-eye-off': !showPassword2,
														}"
                    ></span>
                  </div>
                  <HasError :form="form2" field="password_confirmation" />
                </div>
                <b-button variant="primary" @click="changePassword"> Changer le mot de passe</b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axiosIns from "@/plugins/axiosIns";
import {HasError,} from 'vform/src/components/bootstrap5'
const image = require(`@/assets/img/profile-img.jpg`)
import {computed, onMounted, reactive, ref} from "vue";
import Form from "vform";
import {message} from "ant-design-vue";
import {getCryptedData, storeCryptData} from "@/plugins/auth";

Form.axios = axiosIns

const imageUrl = ref(image)
const showPassword = ref(false)
const showPassword2 = ref(false)
const cities = ref([])

const form2 = reactive(new Form({
  old_password: null,
  password_confirmation: null,
  password: null
}))

const candidate = computed(() => getCryptedData('candidate'))
const form = reactive(new Form({
  fullname: null,
  city: null,
  phone: null,
  email: null,
  image: null,
  cv: null,
  anth: null
}))

const submit = () => {
  form.post('change-profile').then(res => {
    if (res.data.result) {
      storeCryptData('candidate',res.data.result)

    }
  })
}

const changePassword = async () => {
   await form2.post('change-password')
}

const downloadFile = (filename) => {
  axiosIns({
    url: `/download-file/${filename}`,
    method: 'GET',
    responseType: 'blob', // Important! This tells Axios to treat the response as a binary blob
  })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        message.error('Error downloading file');
      });
}

const onFileSelected = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = (event) => {
    imageUrl.value = event.target.result
  }

  reader.readAsDataURL(file)
  form.image = file
}

onMounted(async () => {
  if (candidate.value !== null) {
    form.fill(candidate.value)
    if (candidate.value.image){
      imageUrl.value = require('@/assets/api/profile/'+candidate.value.image)
    }
  }
  const res = await axiosIns.get('model/cities')
  cities.value = res.data
})

</script>
<style scoped>
.dash-listingcontent .group-img input{
  width: 100%;
}

</style>