export default [
    {
        title: 'Tableau de Board',
        icon: 'feather-home',
        route: '/dashboard',
        subject: 'dashboard'
    },
    {
        title: 'Offres Postuler',
        icon: 'feather-check-square',
        route: '/applied_jobs',
        subject: 'applied_jobs'
    },
    {
        title: 'Candidates',
        icon: 'feather-users',
        route: '/acandidates',
        subject: 'acandidates'
    },
    {
        title: "Offres D'emploi",
        icon: 'feather-briefcase',
        route: '/ajobs',
        subject: 'ajobs'
    },
    {
        title: 'Utilisateurs',
        icon: 'feather-users',
        route: '/ausers',
        subject: 'ausers'
    },
    {
        title: 'Frontend',
        icon: 'feather-list',
        route: '/afront',
        subject: 'afront'
    },
    {
        title: 'Profile',
        icon: 'feather-user',
        route: '/profile',
        subject: 'profile'
    },
]