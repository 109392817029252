<script setup>

</script>

<template>
  <div class="partners-section">
    <div class=" container">
      <div class="timeline-sec">
        <div class="row">
          <h1 class="partners-heading aos" data-aos="fade-up">Comment ça marche</h1>
          <div class="col-md-12 aos" data-aos="fade-up">
            <ul class="timeline-wrap">
              <li class="time-box box-bottom">
                <div class="date">
                  <img src="../../../assets/img/how/h1.png" class="img-fluid" alt="img">
                </div>
                <div class="box-content">
                  <p>
                    <span>Je crée un nouveau compte</span>
                  </p>
                </div>
              </li>
              <li class="time-box box-top">
                <div class="date">
                  <img src="../../../assets/img/how/h2.png" class="img-fluid" alt="img">
                </div>
                <div class="box-content">
                  <p>
                    <span>Je complete Mon Profile</span>
                  </p>
                </div>
              </li>
              <li class="time-box box-bottom">
                <div class="date">
                  <img src="../../../assets/img/how/h3.png" class="img-fluid" alt="img">
                </div>
                <div class="box-content">
                  <p>
                    <span>Je joins mon CV</span>
                  </p>
                </div>
              </li>
              <li class="time-box box-top">
                <div class="date">
                  <img src="../../../assets/img/how/h4.png" class="img-fluid" alt="img">
                </div>
                <div class="box-content">
                    <p><span>
                      Je choisis l'offre d'emploi qui me correspond
                    </span></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.box-content p span {
  display: block;
  white-space: nowrap;
  font-size: 18px;
}
.time-box {
  width: 27%;
  min-height: 337px;
}
.time-box .date {
  top: 50%;
  left: 0px;
  width: 180px;
  height: 180px;
}
.box-bottom .box-content::before {
  top: -17px;
}

 .box-top .box-content::before {
   bottom: -36px;
 }

 .box-bottom .box-content::after {
   top: 10px;
 }

.box-top .box-content::after {
  bottom: -3px;
}

.timeline-wrap li:before {
  width: 96px;
  height: 20px;
  top: 50%;
  left: 211px;
}
.timeline-sec {
  background: linear-gradient(270deg, rgba(255, 215, 222, 0) 0%, rgb(115 130 158 / 11%) 45.77%, rgba(255, 215, 222, 0) 97.65%);
}

@media only screen and (max-width: 600px) {
.hidden-sm{
  display: none;
}
}

</style>