<template>
    <div class="main-wrapper">
<!--        <layouts></layouts>-->

        <div class="page-wrapper">
<!--            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />-->
            
            <signuplogin />

<!--            <foot />-->

<!--            <scroll />-->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Create an Account",
            text: "Home",
            text1: "Register",
            name: "/",
        }
    },
}
</script>