<template>
  <!-- Partners Section -->
  <div class="partners-section">
    <div class=" container">
      <p class="partners-heading aos" data-aos="fade-up">Ils Nous Ont Fait Confiance</p>
      <Carousel ref="slider"  class="aos" data-aos="fade-up" :autoplay="3000" :loop="true" :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
        <Slide class="partnerslist d-flex" v-for="item in partners" :key="item.id">
          <li class="carousel__item item">
            <a href="javascript:void(0);">
              <img class="img-fluid" :src="require(`@/assets/api/partners/${item.image}`)" alt="partners">
            </a>
          </li>
        </Slide>
        <template #addons>
          <Navigation/>
        </template>
      </Carousel>

    </div>
  </div>
  <!-- /Partners  Section -->
</template>


<script>
import {Carousel, Slide, Navigation} from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center"
      },

      breakpoints: {
        575: {
          itemsToShow: 3,
          snapAlign: "center"
        },
        // 700px and up
        700: {
          itemsToShow: 3,
          snapAlign: "center"
        },
        // 1024 and up
        1024: {
          itemsToShow: 6,
          snapAlign: "start"
        }
      }
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation
  },
  computed: {
  ...mapGetters({
    partners: 'partners/data'
  })
  },
  mounted() {
    setTimeout(() => {
      this.initialSlide = 0
      if (this.$refs.slider) {
        this.$refs.slider.restartCarousel()
      }
    }, 2000)
  }

}
</script>