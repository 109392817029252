<template>
  <div class="main-wrapper">
    <div class="page-wrapper">
      <div class="blogbanner">

        <div class="blogbanner-content">
          <h1 >Recrutement</h1>
          <span class="blog-hint">Expertise</span>

        </div>
      </div>
      <!--/Blog Banner-->

      <!--Blog Content-->
      <div class="blogdetail-content">
        <div class="container">
        <div class="card">
          <p>Vous souhaitez réussir vos recrutements stratégiques, atteindre rapidement
            la rentabilité et fidéliser vos futurs collaborateurs . Nous aussi, nous souhaitons votre réussite
            Pourtant, les facteurs clés de la réussite existent, maîtrisés et facilement mis en oeuvre.</p>
          <p>Dés que l’arrivée d’un nouveau collaborateur impacte l’avenir de votre entreprise, il devient
            stratégique. Dans ce cas, la démarche doit évoluer et se renforcer, pour un focus
            différent. Plutôt que de trouver le bon candidat ou le candidat idéal, focalisons sur la réussite.
            Cette nouvelle clé d’entrée change fondamentalement les règles et les pratiques.</p>

          <p>L’innovation RFIJ Agency crée ce changement, en changeant de focus et en accentuant la
            préparation, l’intégration et la prise de poste. De plus, le processus s’assure de la création de
            l’ensemble des conditions de la réussite aussi bien pour l’entreprise qui recrute mais aussi pour
            le nouveau collaborateur pour cela on a instauré 4 étapes fondamentales dans notre plan
            d’insertion:</p>
        </div>

          <div class="work-section row">
            <div class="col-lg-3 col-sm-4 d-flex">
              <div class="work-info card">
                <img src="@/assets/img/rec1.png" class="img-fluid" alt="">
                <h5>01</h5>
                <h6>Préparer et
                  Recruter</h6>
                <p>Créer les conditions de la réussite.</p>
                <p>Créer les conditions d’une efficacité opérationnelle rapide.</p>
                <p>Recruter plus facilement votre futur collaborateur grâce à un accès à un nombre élargi et plus important de candidatures.</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-4-4 d-flex">
              <div class="work-info card">
                <h5 class="mt-0">02</h5>
                <h6>Porter et Intégrer </h6>
                <p>Limiter vos risques RH grâce à un contrat de travail sur mesure répondant au besoin du client,Créer un parcours d’intégration complet pour assurer une première fidélisation.</p>
                <img src="@/assets/img/rec2.png" class="img-fluid" alt="">
              </div>
            </div>
            <div class="col-lg-3 col-sm-4 d-flex">
              <div class="work-info card">
                <img src="@/assets/img/rec3.png" class="img-fluid" alt="">
                <h5>03</h5>
                <h6>Accompagner</h6>
                <p>Accompagner votre nouveau collaborateur dans sa prise de poste:</p>
                <div class="requiremnts-info">
                  <ul>
                    <li>pour lever les écueils qui empêcheraient le succès ;</li>
                    <li>pour renforcer ses compétences ;</li>
                    <li>pour piloter son action vers ses objectifs ;</li>
                    <li>pour développer son autonomie.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-4 d-flex">
              <div class="work-info card">
                <h5 class="mt-0">04</h5>
                <h6>Transférer </h6>
                <p>En fin de période , assurer un transfert de fonction du collaborateur :</p>
                <div class="requiremnts-info">
                  <ul>
                    <li>Efficace</li>
                    <li>Autonome</li>
                    <li>Opérationnel</li>
                  </ul>
                </div>
                <img src="@/assets/img/rec4.png" class="img-fluid" alt="">
              </div>
            </div>
          </div>
          <div class="card requiremnts-info">
            <h5>Avantages Collaborateurs  RFIJ Agency</h5>
            <ul>
              <li>Assurer une stabilité professionnelle avec des contrats de longue durée.</li>
              <li>Mettre en place un système de commission qui va améliorer la situation financière des collaborateurs.</li>
              <li>Une assurance mutuelle avec un taux compétitive de 90%.</li>
              <li>Un plan de formation sur mesure pour garantir une parfaite insertion des collaborateurs,</li>
              <li>Un suivi quotidien des employées en s’appuyant sur notre expertise te relationnel</li>
              <li>Assurer la remonté des réclamations de nos collaborateurs au prêt de notre client</li>
              <li>Equipe back office disponible 7/7 et 24/24.</li>
              <li>Possibilité d’évolution rapide ( Plan de carrière )</li>
              <li>Communication très transparente : Groupe WhatsApp et application de gestion ouvert aux
                propositions et réclamations avec l’équipe RFIJ Agency et les Clients.</li>
              <li>Avantages socioprofessionnels:</li>
            </ul>
            <div class="">
              <div class="tag-list">
                <ul class="tags">
                  <li>Facilité d’obtenir un crédit IMMO ou conso avec un taux avantageux.</li>
                  <li>Possibilité d’obtenir un crédit entreprise (de RFIJ Agency )</li>
                  <li>Facilité d’obtenir un visa touristique (CFCIM)</li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</template>
