<template>
  <!-- Header -->
  <header class="header">
    <div class="header-nb">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);">
                        <span class="bar-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="../assets/img/logo/color-no-bg.png" class="img-fluid" alt="Logo">
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="../assets/img/logo/color-no-bg.png" class="img-fluid " alt="Logo">
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i
                class="fas fa-times"></i></a>
          </div>
          <navmenu/>
        </div>
        <ul class="nav header-navbar-rht">
          <li v-if="!isLoggedIn" class="nav-item">
            <router-link class="nav-link header-login" to="/login">Se Connecter</router-link>
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <router-link class="nav-link header-login add-listing" to="/inscrire" v-if="!isLoggedIn">
              S'inscrire
            </router-link>
          </li>
          <li v-if="isLoggedIn" class="nav-item dropdown has-arrow logged-item">
            <a href="#" class="dropdown-toggle profile-userlink" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../assets/img/profile-img.jpg" alt="">
              <span>{{ user?.username }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <router-link class="dropdown-item" to="/dashboard">Tableau de Board</router-link>
              <router-link class="dropdown-item" to="/profile">Profile</router-link>
              <a href="#" class="dropdown-item" @click.prevent="logout">Se Déconnecter</a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>

<script setup>

import {isUserLoggedIn} from "@/router/utils";
import {computed, onMounted} from "vue";
import axiosIns from "@/plugins/axiosIns";
import {getCryptedData, removeData} from "@/plugins/auth";
import { useRouter } from "vue-router";

const router = useRouter()
const isLoggedIn = computed(() => {
  return isUserLoggedIn()
})

const user = computed(() => {
  return getCryptedData('user') ?? null
})

const logout = () => {
  axiosIns.post('logout')
      .then((res) => {
    if (res.data.msg){
      removeData()
      router.push({ name: 'Login' })
    }
  })
}

onMounted(() => {

})
</script>

<script>

export default {
  data() {
    return {}
  },
  mounted() {

    $(document).on('click', '#mobile_btn', function () {
      $('main-wrapper').toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      return false;
    });

    $(document).on('click', '.sidebar-overlay', function () {
      $('html').removeClass('menu-opened');
      $(this).removeClass('opened');
      $('main-wrapper').removeClass('slide-nav');
    });

    $(document).on('click', '#menu_close', function () {
      $('html').removeClass('menu-opened');
      $('.sidebar-overlay').removeClass('opened');
      $('main-wrapper').removeClass('slide-nav');
    });
  },
}
</script>

<style>
.header-nb{
  width: 1500px;
  margin: auto;
  padding: 5px 10px 0 10px;
}
@media (max-width: 170px)
{
  .header-nb{
    width: 1700%;
  }
}
@media (max-width: 576px)
{
  .header-nb{
    width: 100%;
    margin: auto;
  }
  .select-home {
    width: 100% !important;
  }
  .footer-logo a img{
    width: 270px;
  }
}
</style>