<template>
  <!-- Footer -->
  <footer class="footer footer-four">

    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <div class="footer-logo">
                <a href="#"><img src="@/assets/img/logo/color-no-bg.png" alt="logo"></a>
              </div>
              <div class="footer-content" style="max-width: 450px;">
                <p>{{company?.description}}</p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Expertise</h2>
              <ul>
                <li>
                  <router-link to="/recrutement">Recrutement</router-link>
                </li>
                <li>
                  <router-link to="/market-creatif">Marketing Créatif</router-link>
                </li>
                <li>
                  <router-link to="/prod-events">Production Des Evénements</router-link>
                </li>
                <li>
                  <router-link to="/">Autres</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Liens Rapide</h2>
              <ul>
                <li>
                  <router-link to="/">Accueil</router-link>
                </li>
                <li>
                  <router-link to="/jobs">Offres D'emploi</router-link>
                </li>
                <li>
                  <router-link to="/inscrire">S'inscrire</router-link>
                </li>
                <li>
                  <router-link to="/contact">Contact-Nous</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-4 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <h2 class="footer-title">Coordonnées</h2>
              <div class="footer-contact-info">
                <ul>
                  <li><i class="fas fa-location-dot"></i> {{company?.address}}</li>
                  <li><i class="fas fa-envelope"></i> {{company?.email}}</li>
                  <li><i class="fas fa-phone"></i> {{company?.phone}}</li>
                </ul>
              </div>

              <div class="social-icon">
                <ul>
                  <li>
                    <a :href="company?.facebook" target="_blank"><i class="fa-brands fa-facebook"></i></a>
                  </li>
                  <li>
                    <a :href="company?.twitter" target="_blank"><i class="fab fa-twitter"></i> </a>
                  </li>
                  <li>
                    <a :href="company?.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a :href="company?.linkedin" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-12">
              <div class="copyright-text text-center">
                <p class="mb-0">All Copyrights Reserved © {{ new Date().getFullYear() }} - Made By <a
                    href="https://co2dev.com" style="color:#00aee4;">CO2DEV</a>.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->

  </footer>
  <!-- /Footer -->
</template>
<script setup>

import {computed} from "vue";
import {useStore} from "vuex";

const company = computed(() => store.getters["config/getCompaty"] )
const store = useStore()

</script>