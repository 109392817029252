import axios from 'axios'
import ability, { initialAbility } from "@/plugins/casl/ability"
import router from "@/router"
import jwtDefaultConfig from '@/plugins/auth/DefaultJwtConfig'
import { getToken, deleteToken } from "@/plugins/auth"
import { message } from 'ant-design-vue';

const axiosIns = axios.create({

  // baseURL: 'http://127.0.0.1:8000/api',
  baseURL: 'https://api.rfij-agency.com/api',

  // baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // required to handle the CSRF token
})

// Request Interceptor
axiosIns.interceptors.request.use(
  config => {
    const accessToken = getToken()
    if (accessToken) {
      config.headers.Authorization = `${jwtDefaultConfig.tokenType} ${accessToken}`
    }
    
    return config
  },
  error => Promise.reject(error),
)

// Add request/response interceptor
axiosIns.interceptors.response.use(
  response => {
    const { data } = response

    if (data && data.msg) {
       message.success(data.msg)
    }
    
    return Promise.resolve(response)
  },
  error => {
    const { response: { status, data } } = error
    if (status === 401) {
      deleteToken()
      localStorage.removeItem('user')
      localStorage.removeItem('userAbilities')
      localStorage.removeItem('candidate')
      localStorage.removeItem('role')
      ability.update(initialAbility)
      
      return router.replace({ name: 'Login' }).then(() => {message.error('Session Expiré !')})
    }
    if ([403, 405] && data.msg) {
      return message.error(data.msg)
    }

    return Promise.reject(error)
  },
)

export default axiosIns
