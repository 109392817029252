<template>
  <div className="page-wrapper page-admin">
    <div className="p-4">
      <div className="dash-listingcontent dashboard-info">
        <div class="card p-3">
          <a-tabs v-model:activeKey="activeKey" type="card" destroyInactiveTabPane>
            <a-tab-pane key="category" tab="Categories">
              <categories />
            </a-tab-pane>
            <a-tab-pane key="partners" tab="Partenaires">
              <partners />
            </a-tab-pane>
            <a-tab-pane key="stores" tab="Magasins">
              <a-stores />
            </a-tab-pane>
            <a-tab-pane key="config" tab="Configuration">
              <config />
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import Categories from "./categories";
import Partners from "./partners";
import Config from "./config";
import AStores from './stores'
import {ref} from "vue";

const activeKey = ref('category')
</script>

<style >
.ant-tabs-tabpane {
  border: 1px solid rgb(237, 237, 237);
  padding: 10px;
}
</style>